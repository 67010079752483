import React from 'react';
import { useQuery } from 'react-query';

import { OccupancyType } from './constants';
import { useOccupanciesContext } from './OccupanciesContext';
import OccupanciesFormDataTable from './OccupanciesFormDataTable';
import { mapCommunityOccupancyDataToRequest, mapCommunityOccupancyResponseToData } from './utils';
import { createOccupancies, deleteOccupancies, getOccupancies, updateOccupancies } from '../../../apis/CommunityAPI';
import { useRootContext } from '../../../components/layout/RootContext';
import { STATUS } from '../../../constants';

const OccupanciesFormData = () => {
  const { showMessage } = useRootContext();
  const { communityId } = useOccupanciesContext();

  const [dataLoading, setDataLoading] = React.useState(STATUS.IDLE);

  const {
    data: occupancies,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['occupancies', communityId],
    queryFn: () => getOccupancies(communityId as number, { active: 1 }),
    select: (data: any[]) => {
      const formatted_data: OccupancyType[] = data?.map((item: any) => mapCommunityOccupancyResponseToData(item));
      return formatted_data ?? [];
    },
    onError: () => {
      showMessage('error', 'Failed to fetch community Occupancies');
      setDataLoading(STATUS.FAILURE);
    },
    enabled: Boolean(communityId),
    refetchOnWindowFocus: false,
  });

  const handleCreate = (item: Partial<OccupancyType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCommunityOccupancyDataToRequest(item);
    Object.assign(data, { apartment: communityId });
    createOccupancies(communityId as number, [data])
      .then((response) => {
        refetch();
        showMessage('success', 'Community Occupancy added successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to add Community Occupancy');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleUpdate = (item: Partial<OccupancyType>) => {
    setDataLoading(STATUS.LOADING);
    const data = mapCommunityOccupancyDataToRequest(item);
    Object.assign(data, { apartment: communityId });
    updateOccupancies(communityId as number, item.id as number, data)
      .then((response: any) => {
        refetch();
        showMessage('success', 'Community Occupancy changed successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason: any) => {
        console.error(reason);
        showMessage('error', 'Failed to change Community Occupancy');
        setDataLoading(STATUS.FAILURE);
      });
  };

  const handleDelete = (item: Partial<OccupancyType>) => {
    setDataLoading(STATUS.LOADING);
    deleteOccupancies(communityId as number, item.id as number)
      .then((response) => {
        refetch();
        showMessage('success', 'Community Occupancy deleted successfully');
        setDataLoading(STATUS.LOADED);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Community Occupancy');
        setDataLoading(STATUS.FAILURE);
      });
  };

  return (
    <OccupanciesFormDataTable
      loading={dataLoading === STATUS.LOADING ?? isLoading}
      data={occupancies ?? []}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
    />
  );
};

export default OccupanciesFormData;
