import React from 'react';

import styled from 'styled-components';

import { CareFeeDateType, LEVELS } from 'pages/price_healing/Fees/constants';

import { useCareTypesContext } from '../CareTypesContext';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
  gap: 39px;
`;

const LevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LevelName = styled.div`
  font-size: 10px;
  color: (--text-primary);
`;

const LevelValue = styled.div`
  font-size: 12px;
  color: var(--positive-green);
`;

const CareFeesLevels = () => {
  const { careType } = useCareTypesContext();
  const careFees = careType?.careFee;
  return (
    <Container>
      {(LEVELS as Array<keyof CareFeeDateType>)
        .filter((level) => careFees && careFees[level])
        .map((level, index) => (
          <LevelContainer key={`level_${index}`}>
            <LevelName>{`Lv. ${level.slice(-1)}`}</LevelName>
            <LevelValue>{careFees && `$${careFees[level]}`}</LevelValue>
          </LevelContainer>
        ))}
    </Container>
  );
};

export default CareFeesLevels;
