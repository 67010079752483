import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CaretRightFilled } from '@ant-design/icons/lib';
import { Tabs } from 'antd';

import Amenities from './amenities/Amenities';
import CareTypes from './CareTypes/CareTypes';
import { useCommunityInfoContext } from './CommunityInfoContext';
import General from './General/General';

const CommunityInfoContent = () => {
  const { mappedCareData } = useCommunityInfoContext();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('general');
  const careTypesTabs = mappedCareData
    .filter((careType) => careType.communityFee || careType.careFee || careType.livingInfo.length)
    .map((tab: any) => ({
      label: tab.type,
      key: tab.type,
      children: <CareTypes careType={tab} />,
    }));

  const tabs = [
    {
      label: 'General',
      key: 'general',
      children: <General />,
    },
    {
      label: 'Amenities',
      key: 'amenities',
      children: <Amenities />,
    },
  ];

  tabs.push(...careTypesTabs);

  useEffect(() => {
    if (!tabs.some((tab) => tab.key === activeTab)) {
      setActiveTab('general');
    }
  }, [location.pathname, tabs]);

  return (
    <Tabs
      moreIcon={<CaretRightFilled />}
      activeKey={activeTab}
      onChange={setActiveTab}
      tabPosition="top"
      tabBarGutter={30}
      size="small"
      destroyInactiveTabPane
      items={tabs}
    />
  );
};

export default CommunityInfoContent;
