import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { useNavigationContext } from 'components/Beta/NavigationContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px 10px;
`;

const SidebarLogo = () => {
  const { closeMobileSidebar } = useNavigationContext();

  return (
    <Container onClick={closeMobileSidebar}>
      <Link to="/home">
        <img src={'/logos/further-logo-text-light.svg'} />
      </Link>
    </Container>
  );
};

export default SidebarLogo;
