import React from 'react';

import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type Props = {
  file: any;
  onRemove: (id: number) => void;
};
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 10px;
  margin-top: 5px;
  background-color: #f1f1f1;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FileName = styled.span`
  font-size: 12px;
  color: var(--link-blue);
  margin-left: 5px;
  cursor: pointer;
`;

const CommunityIncentivesFilesListDisplay = ({ file, onRemove }: Props) => {
  return (
    <Container>
      <FlexContainer>
        <CheckCircleFilled style={{ color: 'var(--positive-green)', fontSize: 15, marginRight: '5px' }} />
        <FlexContainer>
          <img alt="attachment" src="/icons/attachment.svg" />
          <FileName>
            {file.file_name}.{file.file_extension}
          </FileName>
        </FlexContainer>
      </FlexContainer>
      <CloseOutlined
        style={{ color: 'var(--gray-dark)', fontSize: 10, cursor: 'pointer' }}
        onClick={() => onRemove(file.id)}
      />
    </Container>
  );
};

export default CommunityIncentivesFilesListDisplay;
