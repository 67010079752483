import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { Modal } from 'antd';
import styled from 'styled-components';

import { ShareViaEmailContext } from './ShareViaEmailContext';
import ShareViaEmailMainContent from './ShareViaEmailMainContent';
import ShareViaEmailSidebar from './ShareViaEmailSidebar';
import { User } from './types';
import { generateRandomColor } from './utils';
import { getClients } from '../../../apis/UserAPI';
import { shareView } from '../../../apis/ViewAPI';
import { STATUS } from '../../../constants';
import { useAppSelector } from '../../../store/hook';
import { useRootContext } from '../../layout/RootContext';
import { useCommunityViewContext } from '../CommunityViewContext';

interface Props {
  open: boolean;
  onClose: () => void;
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 0px; /* Example border radius */
    padding: 0; /* Remove padding */
  }
  .ant-checkbox-wrapper {
    font-size: 12px;
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #0ab776; /* Change to your desired color */
      border-color: #0ab776; /* Change to your desired color */
    }
  }
`;

const Container = styled.div`
  flex: 1;
  display: flex;
`;

const ShareViaEmailModal = ({ open, onClose }: Props) => {
  const { showMessage } = useRootContext();
  const { viewId } = useParams();

  const { community, communityId, competitorId } = useCommunityViewContext();
  const { communityCompetitorsList, selectedCompany } = useAppSelector((state) => state.global);
  const competitors = communityCompetitorsList.filter((comp: any) => communityId == comp.parent_id);

  const [loading, setLoading] = useState<string>(STATUS.IDLE);
  const [usersKeyword, setUsersKeyword] = useState<string>('');
  const [users, setUser] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [note, setNote] = useState<string>('');
  const [views, setViews] = useState<number[]>([Number(viewId)]);

  const handleClose = () => {
    setViews([Number(viewId)]);
    setUsersKeyword('');
    setEmails([]);
    setSelectedUsers([]);
    setNote('');

    onClose();
  };

  const handleSend = async () => {
    setLoading(STATUS.LOADING);
    const userEmails = selectedUsers.map((user: any) => user.email);
    const customEmails = emails.map((item: string) => item.trim());
    const allEmails = [...userEmails, ...customEmails.filter(Boolean)];

    const currentCommunityId = competitorId ?? communityId;
    await shareView(currentCommunityId, { community_id: currentCommunityId, views, emails: allEmails, note: note })
      .then(() => {
        setLoading(STATUS.LOADED);
        showMessage('success', 'Community views published.');
      })
      .catch(() => {
        setLoading(STATUS.FAILURE);
        showMessage('error', 'Trouble publishing community views.');
      });
    handleClose();
  };

  const { data } = useQuery({
    queryKey: ['users', selectedCompany, usersKeyword],
    queryFn: () => getClients({ search: usersKeyword, page_size: 300, company: selectedCompany?.id }),
    refetchOnWindowFocus: false,
  });

  const handleUserSearch = (event: any) => {
    setUsersKeyword(event.target.value);
  };

  const onViewSelection = (selection: number[]) => {
    setViews(selection);
  };

  const handleSelect = (id: number) => {
    const selectedUser = users.find((user: User) => user.id == id);
    if (selectedUser) {
      setSelectedUsers((prev: User[]) => [...prev, { ...selectedUser }]);
    }
  };

  const handleDeselect = (id: number) => {
    setSelectedUsers((prev: User[]) => [...prev.filter((user: User) => user.id != id)]);
  };

  useEffect(() => {
    if (data) {
      setUser(data.results.map((user: User) => ({ ...user, color: generateRandomColor() })));
    }
  }, [data]);

  useEffect(() => {
    setViews([Number(viewId)]);
  }, [viewId]);

  return (
    <ShareViaEmailContext.Provider
      value={{
        loading,
        note,
        views,
        emails,
        users,
        selectedUsers,
        setNote,
        setEmails,
        handleClose,
        handleSend,
        handleSelect,
        handleDeselect,
        handleUserSearch,
      }}
    >
      <StyledModal
        centered
        open={open}
        closable={false}
        onOk={handleClose}
        onCancel={handleClose}
        destroyOnClose
        width={940}
        footer={false}
      >
        <Container>
          <ShareViaEmailSidebar
            competitors={competitors}
            viewsSelection={views}
            handleViewSelection={onViewSelection}
          />
          <ShareViaEmailMainContent />
        </Container>
      </StyledModal>
    </ShareViaEmailContext.Provider>
  );
};

export default ShareViaEmailModal;
