export const splitAddress = (input: string): { street: string; restAddress: string } => {
  const index = input.indexOf(',');

  if (index === -1) {
    return { street: input, restAddress: '' };
  }

  return {
    street: input.substring(0, index).trim(),
    restAddress: input.substring(index + 1).trim(),
  };
};
