import React, { useContext } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { WIDGET_TYPES } from 'common/Widgets/constants';

import { CommunityViewWidgetModalContext } from './CommunityViewWidgetModalContext';
import Widget from '../../../common/Widgets/Widget/Widget';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--background-gray);
  padding: 20px;

  &.amenities {
    max-width: 732px;
    max-height: 477px;
    box-sizing: border-box;
    overflow-y: auto;
    align-items: baseline;
  }
`;

const CommunityViewWidgetModalContent = () => {
  const {
    type,
    name,
    note,
    competitors,
    careTypes,
    roomTypes,
    careLevels,
    careLevelsStatsType,
    reviewSources,
    reviewCategories,
    amenityCategories,
    swotCategories,
    initialRange,
    initialFormat,
  } = useContext(CommunityViewWidgetModalContext);

  return (
    <Container className={classNames('widget-modal-content', { amenities: type === WIDGET_TYPES.AMENITIES })}>
      <Widget
        type={type}
        name={name}
        note={note}
        initial_settings={{
          range: initialRange,
          format: initialFormat,
        }}
        filters={{
          competitors,
          careTypes,
          roomTypes,
          careLevels,
          reviewSources,
          reviewCategories,
          swotCategories,
          amenityCategories,
          careLevelsStatsType,
        }}
      />
    </Container>
  );
};

export default CommunityViewWidgetModalContent;
