import React from 'react';

import { Checkbox as AntDCheckbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import styled from 'styled-components';

const StyledCheckbox = styled(AntDCheckbox)`
  color: var(--text-primary);
  font-size: 12px;

  &.green {
    &.ant-checkbox-wrapper {
      .ant-checkbox-inner {
        border-radius: 2px;
        border: 2px solid;
        height: 18px;
        width: 18px;
      }

      .ant-checkbox {
        .ant-checkbox-inner {
          border-color: var(--gray-dark);
        }
      }

      .ant-checkbox-disabled {
        .ant-checkbox-inner {
          opacity: 0.6;
          &:after {
            border-color: white;
          }
        }
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: var(--positive-green);
          background-color: var(--positive-green) !important;
        }
      }

      &:hover {
        .ant-checkbox:hover {
          .ant-checkbox-inner {
            border-color: var(--positive-green);
            opacity: 0.8;
          }
        }
        .ant-checkbox-checked:hover {
          .ant-checkbox-inner {
            border-color: var(--positive-green);
            background-color: var(--positive-green) !important;
            opacity: 0.8;
          }
        }
      }
    }
  }
  
    &.small {
    &.ant-checkbox-wrapper {
        .ant-checkbox-inner {
          border-radius: 2px;
          border: 2px solid;
          height: 14px;
          width: 14px;
          
          &::after {
            height: 8px;
            width: 5px;
          }
        }
  }
`;

const Checkbox = (props: any) => {
  return <StyledCheckbox {...props} />;
};

Checkbox.Group = React.forwardRef<HTMLDivElement, CheckboxGroupProps>((props, ref) => (
  <AntDCheckbox.Group {...props} ref={ref} />
));

export default Checkbox;
