import React from 'react';

import { Tabs } from 'antd';
import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

const Container = styled.div`
  padding: 0 30px;

  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    border-radius: 18px;
    border: solid 1px #d7d7d7;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: 9px 62px 8px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -10px;
      width: 35px;
      height: 100%;
      border-top: 1px solid #d7d7d7;
      border-right: 1px solid #d7d7d7;
      background: #fff;
      box-shadow: 2px -2px 2px 0 rgba(0, 0, 0, 0.1);
      transform: rotate(55deg) skewX(20deg);
      z-index: 1;
    }
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #fff;
    background-color: #00a65a;

    &::after {
      background-color: #00a65a;
      border-color: #00a65a;
    }
  }

  .ant-tabs .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
  }

  .ant-tabs .ant-tabs-tab-btn {
    color: #222;
    font-family: var(--font-bold);
  }
`;

const ShopDataModalHeaderTabs = () => {
  const careTypes = useAppSelector((state) => state.global.livingtypes);
  const careTypesTabs = careTypes.map((tab: any) => ({
    label: tab.type,
    key: tab.type,
  }));

  const tabs = [
    {
      label: 'General',
      key: 'general',
    },
    ...careTypesTabs,
  ];

  return (
    <Container>
      <Tabs tabPosition="top" size="small" destroyInactiveTabPane items={tabs} />
    </Container>
  );
};

export default ShopDataModalHeaderTabs;
