import React from 'react';

import styled from 'styled-components';

import Button from 'components/lib/Button';

import MobileLogo from './MobileLogo';
import HeaderUserActions from '../Header/HeaderUserActions';

const Container = styled.div`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(277deg, var(--sidebar-purple-dark) 0%, var(--sidebar-purple-light) 100%);
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }
`;

const StyledButton = styled(Button)`
  padding: 7px 0;
  cursor: pointer;
`;

type Props = {
  setMobileSidebar: (value: boolean) => void;
}

const MobileTopBar = ({ setMobileSidebar }: Props) => (
  <Container id="mobile-top-bar">
    <StyledButton type='link' onClick={() => setMobileSidebar(true)}>
      <img src={'/logos/hamburger-menu.svg'} height={24} width={24} style={{ color: 'var(--line-gray)' }} />
    </StyledButton>
    <MobileLogo />
    <HeaderUserActions showUserFullName={false} />
  </Container>
);

export default MobileTopBar;
