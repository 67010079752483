import React from 'react';

import styled from 'styled-components';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import {
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_LABELS,
  CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS,
} from '../constants';
import { CMASummaryFilters } from '../types';

const Label = styled.span<{ fontFamily: string }>`
  font-family: ${({ fontFamily }) => fontFamily};
`;

const CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_NAME = 'Room Comparison Type';

const CMASummaryRoomComparisonTypeFilterSelectLabel = () => {
  const { viewFilters } = useCommunityViewContext();
  const filters = viewFilters as CMASummaryFilters;

  const roomComparisonTypeSelected =
    Boolean(filters?.roomComparisonType) &&
    filters?.roomComparisonType === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON;

  const placeholderInfo = roomComparisonTypeSelected
    ? `${CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_NAME}:`
    : CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_NAME;

  return (
    <>
      <Label fontFamily={roomComparisonTypeSelected ? 'var(--font-bold)' : 'var(--font-regular)'}>
        {placeholderInfo}{' '}
      </Label>
      {roomComparisonTypeSelected &&
        filters &&
        CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_LABELS[`${filters?.roomComparisonType as number}`]}
    </>
  );
};

export default CMASummaryRoomComparisonTypeFilterSelectLabel;
