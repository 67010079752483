import React, { useState } from 'react';

import styled from 'styled-components';

import { useRootContext } from 'components/layout/RootContext';
import Button from 'components/lib/Button';
import { useAppSelector } from 'store/hook';

import { useCommunityIncentivesContext } from './CommunityIncentivesContext';
import CommunityIncentivesFilters from './CommunityIncentivesFilters';
import CommunityIncentivesModal from './CommunityIncentivesModal';
import { CommunityIncentivesType } from './types';
import { createIncentive, uploadIncentiveFiles } from '../../apis/CommunityAPI';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CommunityIncentivesControls = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { showMessage } = useRootContext();
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { refetch } = useCommunityIncentivesContext();

  const onCreate = async (values: CommunityIncentivesType) => {
    await createIncentive(selectedCommunity?.id as number, { ...values, community: selectedCommunity?.id })
      .then(async (response: any) => {
        await uploadFiles([{ id: response.id, ...values }]);
        return response;
      })
      .then(() => {
        refetch();
        showMessage('success', `Community Incentive added successfully`);
        setOpenModal(false);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', `Failed to add Community Incentive`);
      });
  };

  const uploadFiles = async (incentives: CommunityIncentivesType[]) => {
    const formData = new FormData();
    formData.append('incentives', JSON.stringify(incentives));
    incentives.forEach((incentive, index) => {
      (incentive.files_upload ?? []).forEach((file: any) => formData.append(`files_${index}`, file.originFileObj));
    });

    await uploadIncentiveFiles(selectedCommunity?.id as number, formData).catch((reason) => {
      console.error(reason);
      showMessage('error', 'Failed to upload Community Incentive files!');
    });
  };

  return (
    <Container>
      <CommunityIncentivesFilters />
      <CommunityIncentivesModal
        openModal={openModal}
        onSubmit={onCreate}
        title="Create Incentive"
        submitButtonText="Create"
        onClose={() => setOpenModal(false)}
      />
      <Button type={'primary'} onClick={() => setOpenModal(true)}>
        Create Incentive
      </Button>
    </Container>
  );
};

export default CommunityIncentivesControls;
