import React, { useEffect, useRef } from 'react';

import { getDocument, PDFPageProxy } from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';

interface PDFThumbnailProps {
  height?: number;
  width?: number;
  fileUrl: string;
  onError: React.Dispatch<React.SetStateAction<boolean>>;
}

const PDFThumbnail: React.FC<PDFThumbnailProps> = ({ fileUrl, onError, height = 134, width = 134 }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const renderingTaskRef = useRef<any>(null);
  const pageRef = useRef<PDFPageProxy | null>(null);

  useEffect(() => {
    let isMounted = true;

    const renderThumbnail = async () => {
      if (!canvasRef.current) return;

      try {
        const pdf = await getDocument(fileUrl).promise;

        if (renderingTaskRef.current) {
          renderingTaskRef.current.cancel();
        }
        if (pageRef.current) {
          pageRef.current.cleanup();
        }

        const page = await pdf.getPage(1);
        pageRef.current = page;

        const originalViewport = page.getViewport({ scale: 1 });
        const scale = Math.min(width / originalViewport.width, height / originalViewport.height);
        const viewport = page.getViewport({ scale });

        const canvas = canvasRef.current;
        const context = canvas?.getContext('2d', { willReadFrequently: true });

        if (canvas && context) {
          canvas.width = width;
          canvas.height = height;

          const offsetX = (canvas.width - viewport.width) / 2;
          const offsetY = (canvas.height - viewport.height) / 2;

          const renderContext = {
            canvasContext: context,
            viewport,
            transform: [1, 0, 0, 1, offsetX, offsetY],
          };

          renderingTaskRef.current = page.render(renderContext);

          await renderingTaskRef.current.promise;
        }
      } catch (error: any) {
        if (error.name !== 'RenderingCancelledException') {
          console.error('Error rendering PDF thumbnail:', error);
          onError(true);
        }
      }
    };

    renderThumbnail();

    return () => {
      isMounted = false;
      if (renderingTaskRef.current) {
        renderingTaskRef.current.cancel();
      }
      if (pageRef.current) {
        pageRef.current.cleanup();
      }
    };
  }, [fileUrl, width, height, onError]);

  return <canvas ref={canvasRef} />;
};

export default PDFThumbnail;
