import React from 'react';
import { useQueries, useQuery } from 'react-query';

import { getAmenities as getAmenitiesAPI, getAmenityCategories } from 'apis/AmenitiesAPI';

import { getAmenitiesDataSource } from './utils';
import { useWidgetContext } from '../Widget/WidgetContext';

export const useGetAmenities = (community_ids: number[]) => {
  const { filters } = useWidgetContext();
  const amenitiesResponse = useQueries(
    community_ids.map((community_id) => ({
      queryKey: ['amenities', community_id],
      queryFn: () => getAmenitiesAPI({ community_id }),
      enabled: Boolean(community_id),
      refetchOnWindowFocus: false,
    }))
  );

  const { data: categories, isLoading: isLoadingCategories } = useQuery({
    queryKey: ['amenities', 'categories'],
    queryFn: () => getAmenityCategories(),
    refetchOnWindowFocus: false,
  });

  if (isLoadingCategories) {
    return [];
  }

  return getAmenitiesDataSource(amenitiesResponse, categories, filters);
};
