import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import AggregationTypePicker from 'components/lib/AggregationTypePicker';

import CommunityViewWidgetModalAmenitiesFilter from './CommunityViewWidgetModalAmenitiesFilter';
import { useCommunityViewWidgetModalContext } from './CommunityViewWidgetModalContext';
import CommunityViewWidgetModalFilter from './CommunityViewWidgetModalFilter';
import CommunityViewWidgetModalFilterSingleSelect from './CommunityViewWidgetModalFilterSingleSelect';
import {
  GRAPH_WIDGET_TYPES,
  WIDGET_FILTER_OPTIONS,
  WIDGET_FILTER_TYPES,
  WIDGET_FILTERS,
  WIDGET_TYPES,
  WIDGET_TYPES_LABELS,
} from '../../../common/Widgets/constants';
import { DATA_DISPLAY_TYPE, DATA_RANGE_TYPE } from '../../../common/Widgets/Widget/constants';
import { useAppSelector } from '../../../store/hook';
import { useCommunityViewContext } from '../CommunityViewContext';
import { AGGREGATION_TYPE_LABELS } from '../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  padding: 20px;
  border-right: 1px solid var(--line-gray);
  gap: 10px;
  overflow-y: auto;
  max-height: 614px;
`;

const MapText = styled.span`
  margin: 10px 0 0;
  font-size: 12px;
  color: var(--text-secondary);
`;

type Props = {
  marginTop?: string;
};

const Title = styled.div<Props>`
  font-family: var(--font-bold);
  font-size: 14px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 'unset')};
`;

const CommunityViewWidgetModalFilters = () => {
  const { livingtypes } = useAppSelector((state) => state.global);
  const {
    type,
    careTypes,
    roomTypes,
    careLevels,
    reviewSources,
    reviewCategories,
    swotCategories,
    careLevelsStatsType,
    initialRange,
    initialFormat,
    setCareTypes,
    setRoomTypes,
    setCareLevels,
    setReviewSources,
    setReviewCategories,
    setSwotCategories,
    setCareLevelsStatsType,
    setInitialRange,
    setInitialFormat,
  } = useCommunityViewWidgetModalContext();
  const { apartmentTypes } = useCommunityViewContext();
  const [otherOptions, setOtherOptions] = useState<number>(careLevelsStatsType);

  const reviewCategoriesOptions = useAppSelector((state) =>
    state.global.reviewCategories?.map((item) => ({
      label: item.label,
      value: item.id,
    }))
  );

  const widgetTypeOptions = [
    {
      label: WIDGET_TYPES_LABELS[type],
      value: type,
    },
  ];

  const careTypesOptions = livingtypes.map((el: any) => ({
    label: el?.type,
    value: el?.id,
  }));

  const roomTypesOptions = apartmentTypes?.map((el: any) => ({
    label: el?.type,
    value: el?.id,
  }));

  const careLevelStatsLabel = AGGREGATION_TYPE_LABELS[otherOptions];

  const setCareLevelsAndStatsType = (value: any) => {
    setCareLevels(value);
    setCareLevelsStatsType(otherOptions);
  };

  useEffect(() => {
    setOtherOptions(careLevelsStatsType);
  }, [careLevelsStatsType]);

  return (
    <Container>
      <Title>Basic Settings</Title>
      <CommunityViewWidgetModalFilter
        title={'Widget Type'}
        disabled={true}
        defaultValue={[type]}
        options={widgetTypeOptions}
      />
      {WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.CARE_TYPE) && (
        <CommunityViewWidgetModalFilter
          title={'Care Types'}
          options={careTypesOptions}
          defaultValue={careTypes}
          onSelect={setCareTypes}
        />
      )}
      {WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.ROOM_TYPE) && (
        <CommunityViewWidgetModalFilter
          title={'Room Types'}
          options={roomTypesOptions}
          defaultValue={roomTypes}
          onSelect={setRoomTypes}
        />
      )}
      {WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.CARE_LEVEL) && (
        <CommunityViewWidgetModalFilter
          title={'Care Levels'}
          options={WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.CARE_LEVEL].map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          otherOptions={<AggregationTypePicker aggregationType={otherOptions} onSelect={setOtherOptions} />}
          otherOptionsLabel={careLevelStatsLabel}
          defaultValue={careLevels}
          onSelect={setCareLevelsAndStatsType}
        />
      )}
      {WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.REVIEW_SOURCE) && (
        <CommunityViewWidgetModalFilter
          title={'Review Source'}
          options={WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.REVIEW_SOURCE].map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          defaultValue={reviewSources}
          onSelect={setReviewSources}
        />
      )}
      {WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.REVIEW_CATEGORY) && (
        <CommunityViewWidgetModalFilter
          title={'Review Category'}
          options={reviewCategoriesOptions}
          defaultValue={reviewCategories}
          onSelect={setReviewCategories}
        />
      )}
      {WIDGET_FILTERS[type]?.includes(WIDGET_FILTER_TYPES.SWOT_CATEGORY) && (
        <CommunityViewWidgetModalFilter
          title={'SWOT Category'}
          options={WIDGET_FILTER_OPTIONS[WIDGET_FILTER_TYPES.SWOT_CATEGORY].map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          defaultValue={swotCategories}
          onSelect={setSwotCategories}
        />
      )}
      {type === WIDGET_TYPES.MAP && (
        <MapText>
          {' '}
          The map widget shows your community and its competitors on an interactive map. You can filter out competitors
          using the filter on the main page.
        </MapText>
      )}
      {type === WIDGET_TYPES.AMENITIES && <CommunityViewWidgetModalAmenitiesFilter />}
      {GRAPH_WIDGET_TYPES.includes(type) && (
        <>
          <Title marginTop="20px">Advanced Settings</Title>
          <CommunityViewWidgetModalFilterSingleSelect
            title="Initial selection"
            value={initialRange}
            options={[
              {
                value: DATA_RANGE_TYPE.NOW,
                label: DATA_RANGE_TYPE.NOW.charAt(0).toUpperCase() + DATA_RANGE_TYPE.NOW.slice(1),
              },
              {
                value: DATA_RANGE_TYPE.OVERTIME,
                label: DATA_RANGE_TYPE.OVERTIME.charAt(0).toUpperCase() + DATA_RANGE_TYPE.OVERTIME.slice(1),
              },
            ]}
            onSelect={setInitialRange}
          />
          <CommunityViewWidgetModalFilterSingleSelect
            title="Initial format"
            value={initialFormat}
            options={[
              {
                value: DATA_DISPLAY_TYPE.GRAPH,
                label: DATA_DISPLAY_TYPE.GRAPH.charAt(0).toUpperCase() + DATA_DISPLAY_TYPE.GRAPH.slice(1),
              },
              {
                value: DATA_DISPLAY_TYPE.TABLE,
                label: DATA_DISPLAY_TYPE.TABLE.charAt(0).toUpperCase() + DATA_DISPLAY_TYPE.TABLE.slice(1),
              },
            ]}
            onSelect={setInitialFormat}
          />
        </>
      )}
    </Container>
  );
};

export default CommunityViewWidgetModalFilters;
