import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import CommunityInfoCompetitorContent from './CommunityInfoCompetitorContent';
import CommunityInfoCompetitorMenu from './CommunityInfoCompetitorMenu';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--line-gray);
  margin-top: -50px;
  padding-top: 50px;
  max-width: 250px;
`;

const Header = styled.div`
  padding-bottom: 10px;
  font-size: 14px;
  color: var(--text-primary);
  padding-left: 20px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  color: var(--text-primary);
  font-family: var(--font-bold);
  font-size: 18px;
  margin-bottom: 6px;
  text-align: center;
`;

const Note = styled.div`
  color: var(--gray-dark);
  font-size: 14px;
  text-align: center;
`;

const CommunityInfoCompetitorOverview = () => {
  const { selectedCommunity, communityCompetitorsList } = useAppSelector((state) => state.global);
  const competitors = communityCompetitorsList.filter((competitor) => selectedCommunity?.id == competitor.parent_id);
  const showInfo = !competitors.length;

  return (
    <Container>
      {showInfo ? (
        <Info>
          <Title>There are no competitors.</Title>
          <Note>Please check at a later date for new competitors</Note>
        </Info>
      ) : (
        <>
          <Sidebar>
            <Header>Competitors</Header>
            <CommunityInfoCompetitorMenu />
          </Sidebar>
          <CommunityInfoCompetitorContent />
        </>
      )}
    </Container>
  );
};

export default CommunityInfoCompetitorOverview;
