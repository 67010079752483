import React, { useState } from 'react';

import { Form, Modal } from 'antd';
import styled from 'styled-components';

import { SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS } from './constants';
import { ShopDataModalContext } from './ShopDataModalContext';
import ShopDataModalHeader from './ShopDataModalHeader';
import ShopDataModalLayout from './ShopDataModalLayout';
import ShopDataModalTabs from './ShopDataModalTabs';
import { ShopDataModalSidebarMenuItem } from './types';

type Props = {
  open: boolean;
  onClose: () => void;
};

const StyledModal = styled(Modal)`
  &&& {
    .ant-modal-content {
      padding: 0px;
      line-height: 1;
      border-radius: 1px;
      user-select: none;
    }
    .ant-modal-body {
      padding: 0px;
    }
  }
`;

const ShopDataModal = ({ open, onClose }: Props) => {
  const [selectedItem, setSelectedItem] = useState<ShopDataModalSidebarMenuItem>(SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS[0]);
  const [form] = Form.useForm();

  const handleSave = () => {
    if (form) {
      form.submit();
    }
  };

  const handleNext = () => {
    const { index } = selectedItem;
    if (index != SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS.length - 1) {
      setSelectedItem(SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS[index + 1]);
    } else {
      onClose();
    }
  };

  const handlePrevious = () => {
    const { index } = selectedItem;
    if (index > 0) {
      setSelectedItem(SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS[index - 1]);
    }
  };

  return (
    <StyledModal open={open} width="fit-content" footer={null} onCancel={onClose}>
      <ShopDataModalContext.Provider
        value={{
          form,
          selectedItem,
          handleSave,
          setSelectedItem,
          handleNext,
          handlePrevious,
        }}
      >
        <ShopDataModalHeader />
        <ShopDataModalTabs />
        <ShopDataModalLayout />
      </ShopDataModalContext.Provider>
    </StyledModal>
  );
};

export default ShopDataModal;
