import React, { useEffect, useState } from 'react';

import { Form } from 'antd';
import styled from 'styled-components';

import Button from 'components/lib/Button';
import Checkbox from 'components/lib/Checkbox';
import Modal from 'components/lib/Modal';
import TextArea from 'components/lib/TextArea';
import Upload from 'components/lib/Upload';
import { useAppSelector } from 'store/hook';

import CommunityIncentivesFilesListDisplay from './CommunityIncentivesFilesListDisplay';
import { CommunityIncentivesType } from './types';

type Props = {
  openModal: boolean;
  incentive?: CommunityIncentivesType;
  onSubmit: (values: CommunityIncentivesType, files: any[]) => Promise<void>;
  title: string;
  submitButtonText: string;
  onClose: () => void;
};

const StyledModal = styled(Modal)`
  &&& .ant-modal {
    max-width: 1040px;
  }
  .ant-modal-close {
    top: 22px;
  }
  &&& .ant-modal-body {
    padding: 25px 0;
  }
  &&& .ant-modal-footer {
    margin-top: 0;
    button {
      font-size: 14px;
    }
  }
  .ant-modal-content {
    max-width: 1040px;
    .ant-modal-header {
      margin-bottom: 0;
    }
    .ant-modal-footer {
      display: flex;
      flex-flow: row-reverse;
      button {
        margin-right: 10px;
        border-radius: 4px;
        width: 155px;
      }
    }
  }
`;

const StyledTitle = styled.div`
  font-family: var(--font-bold);
  margin-bottom: 10px;
  padding: 0 30px;
`;

const StyledInput = styled(TextArea)`
  width: 502px;
`;

const Row = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 30px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Footer = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid var(--line-gray);
`;

const CommunityIncentivesModal = ({ openModal, incentive, title, submitButtonText, onSubmit, onClose }: Props) => {
  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const [form] = Form.useForm();

  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    if (incentive) {
      form.setFieldsValue({
        description: incentive.description,
        living_types: incentive.living_types,
        files_upload: [],
      });
      setFiles(incentive.files || []);
    } else {
      form.resetFields();
      setFiles([]);
    }
  }, [openModal, form, incentive]);

  const handleCancel = () => {
    onClose();
    form.resetFields();
  };

  const onFileRemove = (id: number) => {
    const updatedFiles = files.filter((file: any) => file.id != id);
    setFiles(updatedFiles);
  };

  return (
    <StyledModal open={openModal} onCancel={handleCancel} footer={null} title={title} width="fit-content">
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onSubmit(values, files).then(() => form.resetFields());
        }}
      >
        <StyledTitle>Incentive Description</StyledTitle>
        <Row>
          <Column>
            <Form.Item name="description" rules={[{ required: true, message: 'Please enter a description' }]}>
              <StyledInput placeholder="Enter a description" />
            </Form.Item>
            <Form.Item name="files_upload" valuePropName="files" getValueFromEvent={(e) => e?.fileList}>
              <Upload beforeUpload={() => false} />
            </Form.Item>
            {incentive &&
              files?.map((file: any) => (
                <CommunityIncentivesFilesListDisplay key={file.id} file={file} onRemove={onFileRemove} />
              ))}
          </Column>
          <Column>
            <Form.Item
              name="living_types"
              rules={[{ required: true, message: 'Please select at least one living type' }]}
            >
              <CheckboxGroup>
                {livingTypes.map((livingType) => (
                  <Checkbox key={livingType.id} value={livingType.id} className={'green'}>
                    {livingType.type}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </Form.Item>
          </Column>
        </Row>
        <Footer>
          <Form.Item>
            <Button type={'primary'} htmlType="submit" style={{ marginRight: '10px' }}>
              {submitButtonText}
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Form.Item>
        </Footer>
      </Form>
    </StyledModal>
  );
};

export default CommunityIncentivesModal;
