import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dropdown } from 'antd';
import styled from 'styled-components';

import { useNavigationContext } from 'components/Beta/NavigationContext';

import SidebarSelectorButton from './SidebarSelectorButton';
import SidebarSelectorDropdown from './SidebarSelectorDropdown';
import { ACTOR_TYPE, USER_LEVELS } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../store/hook';
import { handleSetSelectedCommunity, handleSetSelectedManagementCompany } from '../../../../store/slices/globalSlice';
import { useUserSwitch } from '../../hooks';
import { useRootContext } from '../../RootContext';
import { SELECTOR_ITEM_TYPES } from '../constants';
import { SidebarContext } from '../SidebarContext';
import { SelectorItemDatatype } from '../types';

const Container = styled.div`
  position: relative;
  padding: 0px 10px 20px 10px;
`;

const SidebarSelector = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { closeMobileSidebar } = useNavigationContext();

  const { selectedClient, selectedCompany, selectedManagementCompany, selectedCommunity } = useAppSelector(
    (state) => state.global
  );
  const { handleUserSwitch } = useUserSwitch();
  const [open, setOpen] = useState(false);
  const [filterType, setFilterType] = useState<string>(SELECTOR_ITEM_TYPES.ALL);
  const [filterKeyword, setFilterKeyword] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<SelectorItemDatatype>();

  useEffect(() => {
    if (selectedCommunity) {
      setSelectedItem({
        id: selectedCommunity.id,
        type: selectedCommunity.type,
        name: selectedCommunity.name,
      });
    } else if (selectedCompany) {
      setSelectedItem({
        id: selectedCompany.id,
        type: SELECTOR_ITEM_TYPES.COMPANY,
        name: selectedCompany.name,
      });
    }
  }, [selectedCommunity, selectedCompany]);

  const handleItemSelected = (item: SelectorItemDatatype) => {
    if (ACTOR_TYPE.COMMUNITY === item.type) {
      navigate(`/community/${item.id}`);
    } else if (ACTOR_TYPE.COMPETITOR === item.type) {
      navigate(`/competitor/${item.id}`);
    } else if (item.type === ACTOR_TYPE.MANAGEMENT_COMPANY) {
      dispatch(handleSetSelectedCommunity(undefined));
      navigate(`/overview/${item.id}`);
    } else if (item.type === ACTOR_TYPE.COMPANY) {
      dispatch(handleSetSelectedManagementCompany(undefined));
      dispatch(handleSetSelectedCommunity(undefined));
      navigate(`/overview/`);
    } else if (item.type === ACTOR_TYPE.USER) {
      handleUserSwitch(item.id).then(() => {
        setFilterType(SELECTOR_ITEM_TYPES.ALL);
        navigate('/home');
      });
    }
    closeMobileSidebar();
    setOpen(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        filterKeyword,
        filterType,
        setFilterType,
        setFilterKeyword,
        selectedItem,
        handleItemSelected,
      }}
    >
      <Container>
        <Dropdown
          open={open}
          trigger={['click']}
          menu={{ items: [] }}
          destroyPopupOnHide
          onOpenChange={(flag) => {
            setOpen(flag);
          }}
          dropdownRender={(menu: any) => <SidebarSelectorDropdown />}
        >
          <div>
            <SidebarSelectorButton />
          </div>
        </Dropdown>
      </Container>
    </SidebarContext.Provider>
  );
};

export default SidebarSelector;
