import axios from 'axios';
import mem from 'mem';

import { store } from 'store';
import { handleLogout, handleUpdateAccessToken } from 'store/slices/authSlice';

import axiosPublic from './axiosPublic';
import { HTTP_STATUS, REFRESH_TOKEN_LIFETIME } from './constants';
import { getChecksum } from '../apis/utils';

axios.defaults.baseURL = process.env.REACT_APP_API;

const handleRefreshToken = async () => {
  const refreshToken = store.getState().auth.refreshToken;

  try {
    const response = await axiosPublic.post('/api/jwt/refresh/', {
      refresh: refreshToken,
    });

    const { access } = response.data;
    if (!access) {
      // remove login session from local storage--loggedout
      store.dispatch(handleLogout());
    }
    // update access token with new token
    store.dispatch(handleUpdateAccessToken(access));

    return access;
  } catch (err) {
    console.log('Refresh token Expired:', err);
    store.dispatch(handleLogout());
  }
};

export const memorizedRefreshToken = mem(handleRefreshToken, {
  maxAge: REFRESH_TOKEN_LIFETIME,
});

axios.interceptors.request.use(
  async (config) => {
    const accessToken = store.getState().auth.accessToken;

    if (accessToken) {
      Object.assign(config.headers, { Authorization: `Bearer ${accessToken}` });
    }

    // Adding checksums for CorrectChecksum permissions
    let checksum = getChecksum('');
    if (config.method === 'get' && config.params) {
      const uri = axios.getUri(config);
      const searchQueryString = uri.split('?')[1];
      checksum = getChecksum(searchQueryString);
    } else if (config.data) {
      const requestBody = config.data;
      checksum = getChecksum(requestBody);
    }

    Object.assign(config.headers, { 'X-CHECKSUM': checksum });

    return config;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (response) => response,
  async (err) => {
    const config = { ...err?.config };
    // Try to refresh client Access Token on BAD REQUEST and UNAUTHORIZED
    if ([HTTP_STATUS.BAD_REQUEST, HTTP_STATUS.UNAUTHORIZED].includes(err?.response?.status) && !config?.sent) {
      config.sent = true;
      const result = await memorizedRefreshToken();
      if (result) {
        // @ts-ignore
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${result}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(err);
  }
);

export default axios;
