import React from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarSelectorDropdownAction from './SidebarSelectorDropdownAction';
import { useUserSwitch } from '../../hooks';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--text-primary);
  border: 1px solid var(--text-primary);
`;

const SidebarSelectorDropdownActionAdminSwitch = () => {
  const navigate = useNavigate();
  const { currentUser } = useAppSelector((state) => state.auth);

  const { handleUserSwitch } = useUserSwitch();

  const onUserSwitch = () => {
    handleUserSwitch(currentUser?.manager?.id ?? -1).then(() => {
      navigate('/companies');
    });
  };

  return (
    <SidebarSelectorDropdownAction
      icon={
        <IconContainer>
          <img src={'/icons/lock-open-white.svg'} style={{ width: '16px', height: '16px' }} />
        </IconContainer>
      }
      title={'Back to Admin Dashboard'}
      subtitle={`Currently supporting ${currentUser?.name ?? currentUser?.email}`}
      handleClick={onUserSwitch}
    />
  );
};

export default SidebarSelectorDropdownActionAdminSwitch;
