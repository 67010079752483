import React from 'react';

export const VIEW_TYPE = {
  PUBLIC: 1,
  COMPANY: 2,
  MANAGEMENT_COMPANY: 3,
  COMMUNITY: 4,
};

export const CREATE_WIDGET_MODAL_WIDTH = 920;

export const VIEW_MODALS = {
  ADD: 'add',
  RENAME: 'rename',
  DUPLICATE: 'duplicate',
  DELETE: 'delete',
  RESET_LAYOUT: 'reset-layout',
};

export const AGGREGATION_TYPE = {
  AVG: 1,
  MIN: 2,
  MAX: 3,
};

export const DEFAULT_FILTERS = {
  time: null,
  competitors: [],
  careTypes: [],
  roomTypes: [],
  careLevels: [],
  reviewSources: [],
  reviewCategories: [],
  swotCategories: [],
  careLevelsStatsType: AGGREGATION_TYPE.AVG,
};

export const AGGREGATION_TYPE_LABELS = {
  [AGGREGATION_TYPE.AVG]: 'Avg',
  [AGGREGATION_TYPE.MIN]: 'Min',
  [AGGREGATION_TYPE.MAX]: 'Max',
};

export const VIEW_MODALS_CONTENT = {
  [VIEW_MODALS.ADD]: {
    title: 'Add View',
    description: (
      <span>
        This will create a new view that you can build widgets on.
        <br />
        Views display across all communities.
      </span>
    ),
    label: 'Save',
    showInput: true,
  },
  [VIEW_MODALS.RENAME]: {
    title: 'Rename View',
    description: (
      <span>
        You can rename the current view here.
        <br />
        Views display across all communities.
      </span>
    ),
    label: 'Save',
    showInput: true,
  },
  [VIEW_MODALS.DUPLICATE]: {
    title: 'Duplicate View',
    description: (
      <span>
        This will duplicate the current view and save it as a new view.
        <br />
        Views display across all communities.
      </span>
    ),
    label: 'Duplicate',
    showInput: true,
  },
  [VIEW_MODALS.DELETE]: {
    title: 'Delete View?',
    description: (
      <span>
        This will delete the current view for all communities. You will have to recreate it again later if you want it
        back.
      </span>
    ),
    label: 'Delete',
    showInput: false,
  },
  [VIEW_MODALS.RESET_LAYOUT]: {
    title: 'Reset Layout',
    description: (
      <span>This will reset the widget layout to its default arrangement. Are you sure you want to do this?</span>
    ),
    label: 'Reset Layout',
    showInput: false,
  },
};

export const VIEW_CLASSNAME = 'community-view';
