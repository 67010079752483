import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import { getAmenityCategories } from 'apis/AmenitiesAPI';
import { WIDGET_MODAL_TYPE } from 'common/Widgets/Widget/constants';
import { AmenityCategory } from 'components/CommunityInfoBeta/amenities/types';

import { useCommunityViewWidgetModalContext } from './CommunityViewWidgetModalContext';
import CommunityViewWidgetModalSelector from './CommunityViewWidgetModalSelector';

const CommunityViewWidgetModalAmenitiesFilter = () => {
  const { amenityCategories, modalType, setAmenityCategories } = useCommunityViewWidgetModalContext();

  const { data, isFetched } = useQuery({
    queryKey: ['amenity', 'categories'],
    queryFn: () => getAmenityCategories(),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (modalType === WIDGET_MODAL_TYPE.ADD && data?.length && !amenityCategories.length) {
      setAmenityCategories(data.map((category: AmenityCategory) => category.id));
    }
  }, [data]);

  const options =
    data
      ?.sort((a: AmenityCategory, b: AmenityCategory) => a.sequence - b.sequence)
      .map((item: AmenityCategory) => ({
        label: item.name,
        value: item.id,
      })) ?? [];

  return (
    <CommunityViewWidgetModalSelector
      title="Amenity Categories"
      options={options}
      disabled={!isFetched}
      onSelect={setAmenityCategories}
      value={amenityCategories}
    />
  );
};

export default CommunityViewWidgetModalAmenitiesFilter;
