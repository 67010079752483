import React from 'react';

import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import CMASummaryTable from './CMASummaryTable';
import { CMASummaryFilters } from './types';

const CMASummaryTableContainer = () => {
  const { competitors, viewFilters } = useCommunityViewContext();

  const filters = viewFilters as CMASummaryFilters;
  const filteredCompetitors = filters?.competitors?.length
    ? competitors.filter((competitor) => filters?.competitors.includes(competitor.id))
    : competitors;

  return <CMASummaryTable competitors={filteredCompetitors} />;
};

export default CMASummaryTableContainer;
