import { CommunityLevelFeeOutbound, FeeParams } from 'pages/price_healing/Accommodation/types';

import { CommunityCareTypeQueryInterface, FullHomeCommunitiesTypeQueryInterface } from './types';
import { filterUndefinedParams } from './utils';
import axios from '../config/axiosPrivate';

export const getCommunity = async (communityId: number) => {
  const response = await axios.get(`/communities/${communityId}`);
  return response.data;
};

export const updateCommunity = async (communityId: number, payload: any) => {
  const url = `/communities/${communityId}`;

  const response = await axios.patch(url, payload);
  return response.data;
};

export const getCompetitors = async (communityId: number) => {
  const response = await axios.get(`/communities/${communityId}/competitors`);
  return response.data;
};

export const getFullHomeCommunities = async (params: FullHomeCommunitiesTypeQueryInterface) => {
  const url = `/communities/Get_FullHomeCommunities/`;
  const response = await axios.post(url, params);
  return response.data?.payload;
};

export const getCommunityCareTypes = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/care-types?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityRentSquareFootageData = async (
  communityId: number,
  params: CommunityCareTypeQueryInterface
) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/rent-square-footage?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityRentAverageCareCostData = async (
  communityId: number,
  params: CommunityCareTypeQueryInterface
) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/rent-average-care-cost?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityRating = async (communityId: number) => {
  const url = `/communities/${communityId}/dashboard/google-rating-chart`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityFee = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/fee-comparison-graph?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunitySecondPersonFee = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/second-person-fees-chart?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityOccupancies = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/community-occupancy-chart?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityIncentives = async (communityId: number, params: CommunityCareTypeQueryInterface) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `/communities/${communityId}/dashboard/fees-incentives?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityMarketAverages = async (communityId: number) => {
  const url = `/communities/Get_CommunityInfo/${communityId}/`;
  const response = await axios.get(url);
  return response.data;
};

export const getCommunityImages = async (communityId: number, params: any) => {
  const url = `/communities/Get_CommunityCompareImages/${communityId}/`;
  const response = await axios.get(url, { params });
  return response.data;
};

export const getAllCommunities = async (params: any) => {
  const url = '/communities';
  const response = await axios.get(url, { params });
  return response.data;
};

export const getCommunityLevelFees = async (communityId: number, params?: FeeParams) => {
  const cleanParams = filterUndefinedParams(params);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `communities/${communityId}/community-level-fees?${query}`;
  const response = await axios.get(url);
  return response.data;
};

export const createCommunityLevelFees = async (communityId: number, params: CommunityLevelFeeOutbound) => {
  const url = `communities/${communityId}/community-level-fees`;
  const response = await axios.post(url, params);
  return response.data;
};

export const updateCommunityLevelFees = async (
  communityId: number,
  params: CommunityLevelFeeOutbound,
  queryParams?: FeeParams
) => {
  const cleanParams = filterUndefinedParams(queryParams);
  const query = new URLSearchParams(cleanParams).toString();

  const url = `communities/${communityId}/community-level-fees/${params.id}?${query}`;
  const response = await axios.patch(url, params);
  return response.data;
};

export const getOccupancies = async (communityId: number, params: any) => {
  const response = await axios.get(`/communities/${communityId}/occupancies`, { params });
  return response.data;
};

export const createOccupancies = async (communityId: number, payload: any) => {
  const response = await axios.post(`/communities/${communityId}/occupancies`, payload);
  return response.data;
};

export const updateOccupancies = async (communityId: number, occupanciesId: number, payload: any) => {
  const response = await axios.put(`/communities/${communityId}/occupancies/${occupanciesId}`, payload);
  return response.data;
};

export const deleteOccupancies = async (communityId: number, occupanciesId: number) => {
  const response = await axios.delete(`/communities/${communityId}/occupancies/${occupanciesId}`);
  return response.data;
};

export const getFeesIncentives = async (communityId: number, params: any) => {
  const response = await axios.get(`/communities/${communityId}/fee-incentives`, { params });
  return response.data;
};

export const createFeesIncentives = async (communityId: number, payload: any) => {
  const response = await axios.post(`/communities/${communityId}/fee-incentives`, payload);
  return response.data;
};

export const updateFeesIncentives = async (communityId: number, feeIncentiveId: number, payload: any) => {
  const response = await axios.put(`/communities/${communityId}/fee-incentives/${feeIncentiveId}`, payload);
  return response.data;
};

export const deleteFeesIncentives = async (communityId: number, feeIncentiveId: number) => {
  const response = await axios.delete(`/communities/${communityId}/fee-incentives/${feeIncentiveId}`);
  return response.data;
};

export const getCareFees = async (communityId: number, params: any) => {
  const response = await axios.get(`/communities/${communityId}/care-fees`, { params });
  return response.data;
};

export const createCareFees = async (communityId: number, payload: any) => {
  const response = await axios.post(`/communities/${communityId}/care-fees`, payload);
  return response.data;
};

export const updateCareFees = async (communityId: number, careFeeId: number, payload: any) => {
  const response = await axios.put(`/communities/${communityId}/care-fees/${careFeeId}`, payload);
  return response.data;
};

export const deleteCareFees = async (communityId: number, careFeeId: number) => {
  const response = await axios.delete(`/communities/${communityId}/care-fees/${careFeeId}`);
  return response.data;
};

export const getLivingInfo = async (communityId: number, params: any) => {
  const response = await axios.get(`/communities/${communityId}/living-info`, { params });
  return response.data;
};

export const createLivingInfo = async (communityId: number, payload: any) => {
  const response = await axios.post(`/communities/${communityId}/living-info`, payload);
  return response.data;
};

export const updateLivingInfo = async (communityId: number, livingInfoId: number, payload: any) => {
  const response = await axios.put(`/communities/${communityId}/living-info/${livingInfoId}`, payload);
  return response.data;
};

export const deleteLivingInfo = async (communityId: number, livingInfoId: number) => {
  const response = await axios.delete(`/communities/${communityId}/living-info/${livingInfoId}`);
  return response.data;
};

export const getAdditionalLinks = async (communityId: number) => {
  const response = await axios.get(`/communities/${communityId}/additional-links`);
  return response.data;
};

export const createAdditionalLinks = async (communityId: number, payload: any) => {
  const url = `/communities/${communityId}/additional-links`;
  const response = await axios.post(url, payload);
  return response.data;
};

export const getCommunityPhotos = async (communityId: number, params: any) => {
  const response = await axios.get(`/communities/${communityId}/photos`, { params });
  return response.data;
};

export const createPhotos = async (communityId: number, payload: any) => {
  const url = `/communities/${communityId}/photos`;
  const response = await axios.post(url, payload);
  return response.data;
};

export const getIncentives = async (communityId: number, params: any) => {
  const url = `/pricingassistant/${communityId}/incentives`;
  const response = await axios.get(url, { params });
  return response.data;
};

export const createIncentive = async (communityId: number, payload: any) => {
  const url = `/pricingassistant/${communityId}/incentives/`;
  const response = await axios.post(url, payload);
  return response.data;
};

export const updateIncentive = async (communityId: number, incentiveId: number, payload: any) => {
  const url = `/pricingassistant/${communityId}/incentives/${incentiveId}`;
  const response = await axios.put(url, payload);
  return response.data;
};

export const uploadIncentiveFiles = async (communityId: number, payload: any) => {
  const url = `/pricingassistant/${communityId}/incentives/upload`;
  const response = await axios.put(url, payload);
  return response.data;
};

export const deleteIncentive = async (communityId: number, incentiveId: number) => {
  const url = `/pricingassistant/${communityId}/incentives/${incentiveId}`;
  const response = await axios.delete(url);
  return response.data;
};

export const getCommunityBrochureAndDocs = async (communityId: number, params: any) => {
  const response = await axios.get(`/communities/${communityId}/files`, { params });
  return response.data;
};

export const createBrochureAndDocs = async (communityId: number, payload: any) => {
  const url = `/communities/${communityId}/files`;
  const response = await axios.post(url, payload);
  return response.data;
};

export const getCommunityShopNotes = async (communityId: number) => {
  const response = await axios.get(`/communities/${communityId}/shop-notes`);
  return response.data;
};

export const createShopNotes = async (communityId: number, payload: any) => {
  const url = `/communities/${communityId}/shop-notes`;
  const response = await axios.post(url, payload);
  return response.data;
};
