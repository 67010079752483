import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { usePublicationContext } from './PublicationContext';
import CommunityView from '../View/CommunityView';

const Container = styled.div`
  display: flex;
`;

const PublicationView = () => {
  const { hash, viewId } = useParams();
  const { publication, views } = usePublicationContext();
  const [fallbackUrl, setFallbackUrl] = useState('');

  useEffect(() => {
    if (publication && views?.length > 0) {
      const queryString = window.location.search;
      setFallbackUrl(`/public/${hash}/view/${views[0].id}${queryString}`);
    } else {
      setFallbackUrl(`/public/${hash}`);
    }
  }, [hash, viewId, publication]);

  return (
    <Container>
      <CommunityView
        viewId={parseInt(viewId ?? '')}
        communityId={publication?.community_parent_id ?? publication?.community_id}
        competitorId={publication?.community_parent_id ? publication?.community_id : undefined}
        fallbackUrl={fallbackUrl}
        preview
      />
    </Container>
  );
};

export default PublicationView;
