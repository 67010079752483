import React from 'react';

import styled from 'styled-components';

import { useCareTypesContext } from '../CareTypesContext';
import { formatNumber } from '../utils';

type Props = {
  title: string;
  amount?: number;
  subtitle?: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-size: 14px;
  font-family: var(--font-bold);
`;

const Amount = styled.div`
  font-size: 14px;
  color: var(--positive-green);
`;

const Subtitle = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
`;

const FeesItem = ({ title, amount, subtitle }: Props) => {
  const { careType } = useCareTypesContext();
  return (
    <Container>
      <Row>
        <Title>{title}</Title>
        {Boolean(amount) && <Amount>${formatNumber(amount)}</Amount>}
      </Row>
      <Row>
        <Subtitle>{careType?.type}</Subtitle>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Row>
    </Container>
  );
};

export default FeesItem;
