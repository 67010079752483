import React from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import { SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS } from './constants';
import { useShopDataModalContext } from './ShopDataModalContext';

const Container = styled.div`
  border-right: 1px solid var(--line-gray);
  border-top: 1px solid var(--line-gray);
  background: var(--background-gray-light);
`;

const MenuItem = styled.div`
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-primary);
  padding: 13px 30px 14px;
  cursor: pointer;
  user-select: none;
  min-width: 150px;

  &.active {
    color: var(--white);
    background: var(--active-blue);
  }
`;

const ShopDataModalSidebar = () => {
  const { selectedItem, setSelectedItem } = useShopDataModalContext();
  return (
    <Container>
      {SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS?.map((item) => (
        <MenuItem
          key={item.key}
          className={classNames({ active: item.key === selectedItem.key })}
          onClick={() => setSelectedItem(item)}
        >
          {item.label}
        </MenuItem>
      ))}
    </Container>
  );
};

export default ShopDataModalSidebar;
