import React from 'react';

import styled from 'styled-components';

import ShopDataModalContent from './ShopDataModalContent';
import ShopDataModalSidebar from './ShopDataModalSidebar';

const Container = styled.div`
  display flex;
  margin-right: 30px;
  border-right: 1px solid var(--line-gray);
`;

const ShopDataModalLayout = () => {
  return (
    <Container>
      <ShopDataModalSidebar />
      <ShopDataModalContent />
    </Container>
  );
};

export default ShopDataModalLayout;
