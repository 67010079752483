import axios from 'axios';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import { PERSIST_STORE_LOCAL_STORAGE_SELECTOR } from 'store';

import { FILE_EXTENSION, PDF_IMAGE_PADDING } from '../../components/Dashboard/constants';
import { VIEW_CLASSNAME } from '../../components/View/constants';
import { ViewFilters } from '../../components/View/types';
import { convertViewFiltersToQueryString } from '../../components/View/utils';

type PdfExportOrientationType = {
  PORTRAIT: 'portrait';
  LANDSCAPE: 'landscape';
};

export const PDF_EXPORT_ORIENTATION: PdfExportOrientationType = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
};

const HEADLESS_EXPORT_TYPE = {
  PDF: 'PDF',
  IMAGE: 'IMAGE',
};

const exportViewHeadless = async (filename: string, exportType: string, filtersModified: boolean, viewFilters?: ViewFilters) => {
  const localStorageSession = localStorage.getItem(PERSIST_STORE_LOCAL_STORAGE_SELECTOR);
  const parsedSession = localStorageSession ? JSON.parse(localStorageSession) : null;

  const url = filtersModified && viewFilters
    ? `${window.location.href}${convertViewFiltersToQueryString(viewFilters)}`
    : window.location.href;

  try {
    const response = await axios.post('screenshots/', {
      url,
      session: parsedSession,
      export_type: exportType,
      css_selector: `.${VIEW_CLASSNAME}`,
    }, { responseType: 'blob' });

    const blob = new Blob([response.data], { type: exportType === HEADLESS_EXPORT_TYPE.PDF ? 'application/pdf' : 'image/png' });
    const objectURL = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = objectURL;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(objectURL);
  } catch (error) {
    console.error('Export failed:', error);
  }
};

export const exportViewAsPDFHeadless = (filename: string, filtersModified: boolean, viewFilters?: ViewFilters) =>
  exportViewHeadless(filename, HEADLESS_EXPORT_TYPE.PDF, filtersModified, viewFilters);

export const exportViewAsImageHeadless = (filename: string, filtersModified: boolean, viewFilters?: ViewFilters) =>
  exportViewHeadless(filename, HEADLESS_EXPORT_TYPE.IMAGE, filtersModified, viewFilters);

export const exportElementAsPDF = async (ref: any, filename: string, options: { padding: number }) => {
  if (!ref?.current) return;

  const base64data = await getElementAsImage(ref);

  const image = new Image();
  image.src = base64data;
  image.onload = () => {
    const imageHeight = image.height + PDF_IMAGE_PADDING;
    const imageWidth = image.width + PDF_IMAGE_PADDING;

    const orientation = imageWidth > imageHeight ? PDF_EXPORT_ORIENTATION.LANDSCAPE : PDF_EXPORT_ORIENTATION.PORTRAIT;

    const doc = new jsPDF({
      orientation: orientation,
      format: [imageHeight, imageWidth],
      unit: 'px',
      hotfixes: ['px_scaling'],
      compress: true,
    });

    doc.addImage(image, FILE_EXTENSION.PNG, options.padding, options.padding, 0, 0, undefined, 'FAST');
    doc.save(`${filename}.pdf`);
  };
};

export const getElementAsImage = async (ref: any) => {
  const canvas = await html2canvas(ref.current, {
    scale: 2,
    allowTaint: true,
    useCORS: true,
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
    windowWidth: document.documentElement.scrollWidth,
    windowHeight: document.documentElement.scrollHeight,
  });
  return canvas.toDataURL('image/png', 0.1);
};

export const exportElementAsImage = async (ref: any, filename: string) => {
  const data = await getElementAsImage(ref);
  const link = document.createElement('a');

  link.href = data;
  link.download = `${filename}.png`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
