import React, { useState } from 'react';
import { useParams } from 'react-router';

import styled from 'styled-components';

import Button from 'components/lib/Button';
import ShopDataModal from 'components/ShopData/ShopDataModal';
import { useAppSelector } from 'store/hook';

import CommunityInfoHeaderBreadcrumbs from './CommunityInfoHeaderBreadcrumbs';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 30px;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const TitleIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--positive-green);
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 var(--box-shadow-pink);
`;

const Title = styled.span`
  font-family: var(--font-bold);
  font-size: 40px;
  font-weight: 900;
`;

const Link = styled.a`
  text-decoration: none;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CommunityInfoHeader = () => {
  const { competitorId } = useParams();
  const isCompetitor = !!competitorId;

  const { currentUser } = useAppSelector((state) => state.auth);
  const showBetaCommunityDataEntry = currentUser?.feature_flags?.show_beta_community_data_entry;

  const [openShopDataModal, setOpenShopDataModal] = useState<boolean>(false);

  return (
    <Container>
      <ShopDataModal open={openShopDataModal} onClose={() => setOpenShopDataModal(false)} />
      <HeaderContent>
        <CommunityInfoHeaderBreadcrumbs />
        <TitleContainer>
          <TitleIcon>
            <img
              src={'/icons/checklist-white.svg'}
              alt="CommunityInfo"
              style={{
                width: 24,
                height: 24,
              }}
            />
          </TitleIcon>
          <Title>{isCompetitor ? 'Competitor Data' : 'Community Data'}</Title>
        </TitleContainer>
      </HeaderContent>
      {showBetaCommunityDataEntry && (
        <Button type="primary" style={{ marginBottom: '10px' }} onClick={() => setOpenShopDataModal(true)}>
          Create Incentives
        </Button>
      )}
      {!!competitorId && (
        <Link href={`/competitor/${competitorId}`} target="_blank">
          <StyledButton type="primary">
            <img src="/icons/auto-graph.svg" height="24px" width="24px" />
            <span>Open Overview</span>
          </StyledButton>
        </Link>
      )}
    </Container>
  );
};

export default CommunityInfoHeader;
