import React, { useContext } from 'react';

import { CloseOutlined } from '@ant-design/icons/lib';
import classNames from 'classnames';
import styled from 'styled-components';

import { CommunityViewWidgetModalContext } from './CommunityViewWidgetModalContext';
import { WIDGET_TYPES } from '../../../common/Widgets/constants';
import Input from '../../lib/Input';

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
`;

const LabeledInput = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  span {
    font-family: var(--font-bold);
    width: 55px;
  }
  input {
    width: 100%;
  }
`;

const CloseContainer = styled.div`
  margin: 10px 5px 0px 20px;
  color: var(--gray-dark);
  margin-left: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 10px 20px;
  border-bottom: 1px solid var(--line-gray);
  &.map {
    background: var(--background-gray);
    border-bottom: none;
    ${Inputs} {
      display: none;
    }
    ${CloseContainer} {
      margin-left: auto;
    }
  }
`;

const CommunityViewWidgetModalInfo = () => {
  const { name, note, type, setName, setNote, onClose } = useContext(CommunityViewWidgetModalContext);

  const handleTitleChange = (e: any) => {
    setName(e.target.value);
  };

  const handleNoteChange = (e: any) => {
    setNote(e.target.value);
  };

  return (
    <Container
      className={classNames({
        map: type === WIDGET_TYPES.MAP,
      })}
    >
      <Inputs>
        <LabeledInput>
          <span>Title</span>
          <Input value={name} onChange={handleTitleChange} />
        </LabeledInput>

        <LabeledInput>
          <span>Note</span>
          <Input value={note} placeholder="Enter a descriptive note here (optional)" onChange={handleNoteChange} />
        </LabeledInput>
      </Inputs>
      <CloseContainer>
        <CloseOutlined onClick={onClose} />
      </CloseContainer>
    </Container>
  );
};

export default CommunityViewWidgetModalInfo;
