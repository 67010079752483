import { Layout as LayoutType } from 'react-grid-layout';

import { WIDGET_TYPES } from 'common/Widgets/constants';

export const GRID_LAYOUT_COLUMNS = 24;
export const RESPONSIVE_GRID_LAYOUT_ROW_HEIGHT = 42;
const SINGLE_METRIC_WIDGET_LAYOUT_HEIGHT = 3;
const SINGLE_METRIC_WIDGETS_IN_ONE_ROW = 4;
const GRAPH_WIDGET_LAYOUT_HEIGHT = 6;
const AMENITIES_WIDGET_LAYOUT_HEIGHT = 12;

const SINGLE_METRIC_WIDGET = {
  width: GRID_LAYOUT_COLUMNS / 3,
  minWidth: GRID_LAYOUT_COLUMNS / 4,
  height: SINGLE_METRIC_WIDGET_LAYOUT_HEIGHT,
  minHeight: SINGLE_METRIC_WIDGET_LAYOUT_HEIGHT,
};

const GRAPH_WIDGET = {
  width: GRID_LAYOUT_COLUMNS / 2,
  minWidth: GRID_LAYOUT_COLUMNS / 3,
  height: GRAPH_WIDGET_LAYOUT_HEIGHT,
  minHeight: GRAPH_WIDGET_LAYOUT_HEIGHT,
};

const getGraphWidgetsInitialYPosition = (singleMetricWidgets: number, includeMapWidget = false) => {
  const offset = includeMapWidget ? SINGLE_METRIC_WIDGET_LAYOUT_HEIGHT : 0;
  const singleMetricsWidgetRowsCount = Math.ceil(singleMetricWidgets / SINGLE_METRIC_WIDGETS_IN_ONE_ROW) + offset;

  return singleMetricsWidgetRowsCount * SINGLE_METRIC_WIDGET_LAYOUT_HEIGHT;
};

type PopulateLayoutProps = {
  items: any[];
  columns: number;
  width: number;
  height: number;
  x: number;
  y: number;
  minHeight: number;
  minWidth: number;
};

const populateLayout = ({ items, columns, width, height, x, y, minHeight, minWidth }: PopulateLayoutProps) => {
  const layout: any[] = [];

  items.forEach((item: any) => {
    layout.push({
      i: `${item.id}`,
      x: x * width,
      y: y, // puts it at the bottom
      w: width,
      h: height,
      minW: minWidth,
      minH: minHeight,
    });

    if ((x + 1) * width + width > columns) {
      x = 0;
      y += height;
    } else {
      x += 1;
    }
  });

  return layout;
};

export const generateLayout = (singleMetricItems: any[], graphMetricItems: any[]) => {
  const mapWidget = graphMetricItems.find((item) => item.name === 'Map');
  let mapWidgetLayoutItem = [];
  if (mapWidget) {
    mapWidgetLayoutItem = populateLayout({
      items: [mapWidget],
      columns: GRID_LAYOUT_COLUMNS,
      width: GRID_LAYOUT_COLUMNS,
      height: GRAPH_WIDGET.height,
      x: 0,
      y: 0,
      minWidth: GRAPH_WIDGET.minWidth,
      minHeight: GRAPH_WIDGET.minHeight,
    });
  }

  const singleMetricWidgetLayoutItems = populateLayout({
    items: singleMetricItems,
    columns: GRID_LAYOUT_COLUMNS,
    width: SINGLE_METRIC_WIDGET.width,
    height: SINGLE_METRIC_WIDGET.height,
    x: 0,
    y: graphMetricItems.find((item) => item.type === WIDGET_TYPES.MAP) ? SINGLE_METRIC_WIDGET_LAYOUT_HEIGHT : 0,
    minHeight: SINGLE_METRIC_WIDGET.minHeight,
    minWidth: SINGLE_METRIC_WIDGET.minWidth,
  });
  const graphWidgetLayoutItems = populateLayout({
    items: graphMetricItems.filter((item) => item.type !== WIDGET_TYPES.MAP && item.type !== WIDGET_TYPES.AMENITIES),
    columns: GRID_LAYOUT_COLUMNS,
    width: GRAPH_WIDGET.width,
    height: GRAPH_WIDGET.height,
    x: 0,
    y: getGraphWidgetsInitialYPosition(singleMetricItems.length, mapWidget),
    minWidth: GRAPH_WIDGET.minWidth,
    minHeight: GRAPH_WIDGET.minHeight,
  });

  const result = [...mapWidgetLayoutItem, ...singleMetricWidgetLayoutItems, ...graphWidgetLayoutItems].filter(
    (item) => !!item
  );

  const amenitiesWidget = graphMetricItems.find((item) => item.type === WIDGET_TYPES.AMENITIES);
  if (amenitiesWidget) {
    const amenitiesWidgetLayoutItem = populateLayout({
      items: [amenitiesWidget],
      columns: GRID_LAYOUT_COLUMNS,
      width: GRID_LAYOUT_COLUMNS,
      height: AMENITIES_WIDGET_LAYOUT_HEIGHT,
      x: 0,
      y: Math.max(...result.map((item: LayoutType) => item.y)) + GRAPH_WIDGET_LAYOUT_HEIGHT,
      minWidth: GRID_LAYOUT_COLUMNS / 2,
      minHeight: GRAPH_WIDGET_LAYOUT_HEIGHT,
    });
    result.push(...amenitiesWidgetLayoutItem);
  }

  return result;
};

export const getNewLayoutItem = (
  itemId: string,
  isSingleWidget: boolean,
  isAmenitiesWidget: boolean,
  initialLayout: any[]
) => {
  const currentMaxHeight = initialLayout.map((item) => item.h).reduce((a, b) => a + b, 0);

  let w, h, minW, minH;
  if (isSingleWidget) {
    w = SINGLE_METRIC_WIDGET.width;
    h = SINGLE_METRIC_WIDGET.height;
    minW = SINGLE_METRIC_WIDGET.minWidth;
    minH = SINGLE_METRIC_WIDGET.minHeight;
  } else if (isAmenitiesWidget) {
    w = GRID_LAYOUT_COLUMNS;
    h = AMENITIES_WIDGET_LAYOUT_HEIGHT;
    minW = GRAPH_WIDGET.minWidth;
    minH = GRAPH_WIDGET.minHeight;
  } else {
    w = GRAPH_WIDGET.width;
    h = GRAPH_WIDGET.height;
    minW = GRAPH_WIDGET.minWidth;
    minH = GRAPH_WIDGET.minHeight;
  }

  return { i: itemId, x: 0, y: currentMaxHeight + 1, w, h, minW, minH };
};

export const RESPONSIVE_GRID_LAYOUT_BREAKPOINTS = {
  xxl: 2000,
  xl: 1600,
  lg: 1200,
  md: 1000,
  sm: 810,
  xs: 400,
  xxs: 0,
};

export const RESPONSIVE_GRID_LAYOUT_COLS = {
  xxl: GRID_LAYOUT_COLUMNS,
  xl: GRID_LAYOUT_COLUMNS,
  lg: GRID_LAYOUT_COLUMNS,
  md: GRID_LAYOUT_COLUMNS,
  sm: GRID_LAYOUT_COLUMNS,
  xs: 4,
  xxs: 2,
};

/**
 * @param initialLayout the layout in the react component State
 * @param updatedLayout this comes directly from the ResponsiveGridLayout, with the updated Layout state after a widget
 * is added into, or removed from the Layout
 */
export const getUpdatedLayout = (
  initialLayout: any[] | null,
  updatedLayout: any[],
  singleMetricItems: any[],
  graphMetricItems: any[]
) => {
  if (initialLayout?.length) {
    if (updatedLayout.length < initialLayout.length) {
      // widget removed from the initialLayout
      return updatedLayout;
    } else if (updatedLayout.length > initialLayout.length) {
      // widget added into the initialLayout
      const newWidgetId = updatedLayout.find((item: any) => !initialLayout.some((el: any) => el.i === item.i))?.i;
      const isSingleWidget = singleMetricItems.some((e: any) => e.id === Number.parseInt(newWidgetId, 10));
      const isAmenitiesWidget = Boolean(
        graphMetricItems.find((e: any) => e.id === Number.parseInt(newWidgetId) && e.type === WIDGET_TYPES.AMENITIES)
      );

      const newLayoutItem = getNewLayoutItem(newWidgetId, isSingleWidget, isAmenitiesWidget, initialLayout);
      return [...initialLayout, newLayoutItem];
    }
    return [];
  } else {
    const generatedLayout = generateLayout(singleMetricItems, graphMetricItems);
    return generatedLayout;
  }
};
