import React from 'react';

import styled from 'styled-components';

import { useWidgetContext } from './WidgetContext';
import AmenitiesWidgetContent from '../Amenities/AmenitiesWidgetContent';
import CardWidgetContent from '../CardWidget/CardWidgetContent';
import { GRAPH_WIDGET_TYPES, SINGLE_METRIC_WIDGET_TYPES, WIDGET_TYPES } from '../constants';
import GraphWidgetContent from '../GraphWidget/GraphWidgetContent';
import IncentivesWidgetContent from '../Incentives/IncentivesWidgetContent';
import MapWidgetContent from '../Map/MapWidgetContent';
import PhotosWidgetContent from '../Photos/PhotosWidgetContent';
import ReviewsWidgetContent from '../Reviews/ReviewsWidgetContent';
import SwotPerformanceWidgetContent from '../Swot/SwotPerformanceWidgetContent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
`;

const WidgetContent = () => {
  const { type } = useWidgetContext();

  return (
    <Container className="mini-scroll">
      {SINGLE_METRIC_WIDGET_TYPES.includes(type) && <CardWidgetContent />}
      {GRAPH_WIDGET_TYPES.includes(type) && <GraphWidgetContent />}
      {WIDGET_TYPES.REVIEWS === type && <ReviewsWidgetContent />}
      {WIDGET_TYPES.INCENTIVES === type && <IncentivesWidgetContent />}
      {WIDGET_TYPES.PHOTOS === type && <PhotosWidgetContent />}
      {WIDGET_TYPES.SWOT_PERFORMANCE === type && <SwotPerformanceWidgetContent />}
      {WIDGET_TYPES.MAP === type && <MapWidgetContent />}
      {WIDGET_TYPES.AMENITIES === type && <AmenitiesWidgetContent />}
    </Container>
  );
};

export default WidgetContent;
