import React, { createContext, useContext } from 'react';

import { FormInstance } from 'antd/es/form';

import { SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS } from './constants';
import { ShopDataModalSidebarMenuItem } from './types';

type ShopDataModalContentType = {
  form: FormInstance | null;
  selectedItem: ShopDataModalSidebarMenuItem;
  handleSave: () => void;
  handleNext: () => void;
  handlePrevious: () => void;
  setSelectedItem: (item: ShopDataModalSidebarMenuItem) => void;
};

export const ShopDataModalContext = createContext<ShopDataModalContentType>({
  form: null,
  selectedItem: SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS[0],
  handleSave: () => {
    // Does Nothing
  },
  handleNext: () => {
    // Does Nothing
  },
  handlePrevious: () => {
    // Does Nothing
  },
  setSelectedItem: () => {
    // Does Nothing
  },
});

export const useShopDataModalContext = () => useContext(ShopDataModalContext);
