import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Button, Input, Select, Table, TableColumnsType } from 'antd';
import styled from 'styled-components';

import { initiateAmenitiesRequest } from 'apis/AmenitiesAPI';
import { getAllCommunities } from 'apis/CommunityAPI';
import { getCompanies } from 'apis/UserAPI';

import AdminPageHeader from '../../common/Admin/AdminPageHeader';
import { ACTOR_TYPE, COMMUNITY_TYPE } from '../../constants';
import { useRootContext } from '../layout/RootContext';

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  gap: 10px;
`;

const COLUMNS: TableColumnsType = [
  {
    title: 'Company',
    key: 'company_name',
    render: (_, row) => row.company?.name || 'N/A',
  },
  {
    title: 'Community',
    dataIndex: 'comunity_name',
    key: 'comunity_name',
    render: (_, row) => row.comunity_name,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (_, row) => (row.type == COMMUNITY_TYPE.COMMUNITY ? 'Community' : 'Competitor'),
  },
  Table.SELECTION_COLUMN,
];

const AmenitiesFetchForm = () => {
  const { showMessage } = useRootContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [fetchAmenitiesLoading, setFetchAmenitiesLoading] = useState(false);

  const [communityName, setCommunityName] = useState('');
  const [communityType, setCommunityType] = useState<number | null>(null);
  const [companyId, setCompanyId] = useState<number | null>(null);

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    isFetched: companiesFetched,
  } = useQuery({
    queryKey: ['companies'],
    queryFn: () => getCompanies({}),
    select: (data: any) => data?.results,
    refetchOnWindowFocus: false,
  });

  const { data: communities, isLoading: isLoadingCommunities } = useQuery({
    queryKey: ['communities', companyId, communityType, communityName],
    queryFn: () => getAllCommunities({ companies: [companyId], type: communityType, name: communityName }),
    refetchOnWindowFocus: false,
    enabled: companiesFetched && Boolean(companyId || communityName),
  });

  const fetchAmenities = async () => {
    try {
      setFetchAmenitiesLoading(true);
      await initiateAmenitiesRequest(selectedRowKeys.map(Number));
      showMessage('success', 'Initiated amenities fetching for selected communities.');
      setFetchAmenitiesLoading(false);
    } catch (e: any) {
      setFetchAmenitiesLoading(false);
      showMessage('error', `Something went wrong while fetching amenities: ${e.response?.data}`);
    }
  };

  return (
    <div style={{ padding: '20px', width: '100%' }}>
      <AdminPageHeader title={'Amenities'} description={'Fetch amenities from A Place For Mom'} />
      <Container>
        <Select
          allowClear
          placeholder="Company"
          showSearch
          disabled={fetchAmenitiesLoading}
          filterOption={(input: any, option: any) => option.label.toLowerCase().includes(input.toLowerCase())}
          options={companies?.map((company: any) => ({ value: company.id, label: company.name }))}
          onChange={(e) => setCompanyId(e)}
          style={{ width: '200px' }}
        />
        <Select
          options={[
            { label: ACTOR_TYPE.COMMUNITY, value: COMMUNITY_TYPE.COMMUNITY },
            { label: ACTOR_TYPE.COMPETITOR, value: COMMUNITY_TYPE.COMPETITOR },
          ]}
          allowClear
          disabled={fetchAmenitiesLoading}
          placeholder="Type"
          onChange={(e) => setCommunityType(e)}
          style={{ width: '150px' }}
        />
        <Input
          disabled={fetchAmenitiesLoading}
          placeholder="Community name"
          style={{ width: '200px' }}
          onChange={(e) => setCommunityName(e.target.value)}
          allowClear
        />
        <Button onClick={fetchAmenities} disabled={selectedRowKeys.length === 0 || fetchAmenitiesLoading}>
          Fetch Amenities
        </Button>
      </Container>
      <Table
        dataSource={communities ?? []}
        columns={COLUMNS}
        loading={isLoadingCompanies || isLoadingCommunities}
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys),
        }}
      />
    </div>
  );
};

export default AmenitiesFetchForm;
