import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, message, Spin, Typography } from 'antd';
import { format } from 'date-fns';

import axios from 'config/axiosPrivate';
import LocationSearchInput from 'formComponents/LocationSearchInput';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleClearCommunityData, handleStoreCompetitorsInfo } from 'store/slices/communitySlice';

import CompetitorSearchItem from './CompetitorSearchItem';
import { mapCareFeeDataToRequest, mapCommunityFeeDataToRequest } from '../price_healing/Fees/utils';

interface Props {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  onCreate: (response: any) => void;
}

const CompetitorsForm = ({ current, setCurrent, onCreate }: Props) => {
  const navigate = useNavigate();
  const { managementCompanyId } = useParams();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const basic_info = useAppSelector((state) => state.community.basic_info);
  const accessibilities = useAppSelector((state) => state.community.accessibility);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const accommodation_and_care = useAppSelector((state) => state.community.cares_info);
  const communityFees = useAppSelector((state) => state.community.communityFees);
  const careFees = useAppSelector((state) => state.community.careFees);
  const competitors: any[] = useAppSelector((state) => state.community.competitors);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSetLocation = (data: any) => {
    const d = competitors || [];
    const cp = [...d];
    cp.push(data);
    dispatch(handleStoreCompetitorsInfo(cp));
  };
  const handleRemove = (index: number) => {
    const cp = [...competitors];
    cp.splice(index, 1);
    dispatch(handleStoreCompetitorsInfo(cp));
  };

  const handleCreateCommunity = async () => {
    // Create Community instance. Response used in future requests
    try {
      setLoading(true);
      const basicResponse = await axios
        .post('/communities/Save_AddComm_CommunityInfo/', {
          data: [
            {
              ...basic_info,
              Accessibility:
                basic_info === null || basic_info.accessibility == 'private'
                  ? []
                  : accessibilities.map((ac) => ({
                      ...ac,
                      company_id: basic_info?.company_id,
                      updated_at: format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
                    })),
            },
          ],
        })
        .then((res) => res.data);

      // Bulk create Accommodation and Care (Living Info) records
      const accommodationCareData: any[] = [];
      accommodation_and_care.forEach((accommodation: any) => {
        accommodation.livingTypes.forEach((livingType: any) => {
          if (livingType.value) {
            const pricePerSquareFoot =
              livingType.value && accommodation.squareFootage
                ? (livingType.value / accommodation.squareFootage).toFixed(2)
                : undefined;

            const accommodationEntry = {
              apartment: basicResponse.id,
              apartment_type_id: accommodation.apartmentTypeId,
              living_type_id: livingType.id,
              base_rent: livingType.value,
              sq_footage: accommodation.squareFootage,
              notes: accommodation.notes,
              price_per_sq: pricePerSquareFoot,
            };
            accommodationCareData.push(accommodationEntry);
          }
        });
      });

      axios
        .post(`/communities/${basicResponse?.id}/living-info`, accommodationCareData)
        .then((response) => response.data)
        .catch((reason) => {
          console.error(reason);
        });

      // Bulk create community Fee Incentives records
      const communityFeeData = communityFees.map((el: any) => {
        const communityFeeData = {
          ...el,
          additional_fee: el?.additional_fee || 0,
          community_fee: el?.community_fee || 0,
          pet_fee: el?.pet_fee || 0,
          respite_fee: el?.respite_fee || 0,
          second_person_fee: el?.second_person_fee || 0,
        };

        const data = mapCommunityFeeDataToRequest(communityFeeData);
        Object.assign(data, { apartment: basicResponse.id });
        return data;
      });

      await axios.post(`communities/${basicResponse?.id}/fee-incentives`, communityFeeData).then((res) => res.data);

      // Bulk create community Care Fees records
      const communityCareFeeData = careFees.map((el: any) => {
        const communityCareFeeData = {
          ...el,
          level_1: el?.level_1 || 0,
          level_2: el?.level_2 || 0,
          level_3: el?.level_3 || 0,
          level_4: el?.level_4 || 0,
          level_5: el?.level_5 || 0,
          level_6: el?.level_6 || 0,
          level_7: el?.level_7 || 0,
          level_8: el?.level_8 || 0,
          medication_management_fee: el?.medication_management_fee || 0,
          average_care_cost: el?.average_care_cost || 0,
        };

        const data = mapCareFeeDataToRequest(communityCareFeeData);
        Object.assign(data, { apartment: basicResponse.id });
        return data;
      });

      await axios.post(`communities/${basicResponse?.id}/care-fees`, communityCareFeeData).then((res) => res.data);

      // Create Competitor Community instance
      const competitorPayload = competitors.map((cmp) => ({
        comunity_name: cmp.name,
        address: cmp.formatted_address,
        state: cmp.state,
        latitude: cmp.lat,
        longitude: cmp.lng,
        city: cmp.city,
        website: cmp.website,
        zip_code: cmp.zip_code,
        google_map_location: cmp.place_id,
        google_formatted_address: cmp.formatted_address,
        a_place_for_mom_id: cmp.a_place_for_mom_id || '',
        caring_url: cmp.caring_url || '',
        apartment_id: basicResponse?.id,
        type: 'competitor',
        collected_by: '',
        owner: '',
        op: 'added',
        region: '',
        managed_by: '',
        phone_number: cmp.phone_number || '',
        google_rating: cmp.rating || 0,
        google_reviews: cmp.user_ratings_total || 0,
        payment_types: '',
        business_model: '',
        comp_level: '',
        occupancy: '',
        primary_address: '',
        ratings: '',
        comment: '',
        avg_rating: null,
        last_renovated_date: '',
        sub_region: '',
        county: '',
        apartment_logo: '',
        further_id: null,
        comunity: '',
        management_company: null,
        created_from_data: '',
        company: null,
        CompanyID: null,
        CompanyId: null,
        company_id: basic_info?.company_id,
        CompanyName: '',
        ManagementCompanyId: null,
        ManagementCompanyName: '',
        management_company_id: basic_info?.management_company_id,
        year_opened: '',
        created_by: currentUser?.name || '',
        user_id: currentUser?.user_id || 1,
      }));
      if (competitorPayload.length > 0) {
        await axios
          .post('communities/Save_AddComm_CompetitorInfo/', {
            data: competitorPayload,
          })
          .then((res) => res.data);
      }
      dispatch(handleClearCommunityData());
      setLoading(false);

      // Handle routing outside this component
      onCreate(basicResponse);
    } catch (err: any) {
      setLoading(false);
      console.log(err);
      messageApi.open({
        type: 'error',
        content: err?.response?.data[0],
        duration: 5,
      });
    }
  };

  return (
    <div>
      {contextHolder}
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgb(240 240 240 / 71%)',
            zIndex: 99,
          }}
        >
          <Spin size="large" />
        </div>
      )}

      <div style={{ minHeight: 'calc(100vh - 430px)' }}>
        <div style={{ maxWidth: '570px', margin: 'auto' }}>
          <div
            style={{
              maxWidth: '570px',
              margin: '0 auto',
            }}
          >
            <LocationSearchInput getLocationBySearch={handleSetLocation} placeholder="Search competitors to add" />
          </div>
          <div style={{ padding: '10px 30px', marginTop: 30 }}>
            {competitors &&
              competitors.map((item, idx) => (
                <CompetitorSearchItem key={idx} item={item} handleRemove={() => handleRemove(idx)} />
              ))}
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          borderTop: '2px solid #eee',
          padding: '10px 30px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '80px',
          marginTop: 30,
        }}
      >
        <Button onClick={() => navigate(`/overview/${managementCompanyId}`)}>Cancel</Button>
        <div style={{ textAlign: 'center', padding: '20px 50px', maxWidth: '70%' }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {basic_info?.comunity_name}
          </Typography.Title>
          <Typography.Paragraph style={{ color: '#ccc', fontSize: 16 }}>{basic_info?.address}</Typography.Paragraph>
        </div>
        <div>
          {current !== 0 && (
            <Button style={{ marginRight: 10 }} onClick={() => setCurrent((prevState) => prevState - 1)}>
              Previous Step
            </Button>
          )}
          <Button type="primary" htmlType="button" onClick={handleCreateCommunity} loading={loading} disabled={loading}>
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompetitorsForm;
