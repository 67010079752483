import React from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import { getCommunity, getCompetitors } from 'apis/CommunityAPI';
import { WIDGET_TYPES_LABELS } from 'common/Widgets/constants';
import Widget from 'common/Widgets/Widget/Widget';
import Button from 'components/lib/Button';
import { CommunityViewContext } from 'components/View/CommunityViewContext';
import { getWidgetFilter } from 'components/View/utils';
import { RecommendationCommunityFilterType } from 'types/recommendations';

const Menu = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  max-width: 585px;
`;

const Container = styled.div`
  &&&&&& .amenities {
    th {
      &:nth-last-of-type(2) {
        .column-header-label {
          max-width: 104px;
        }
      }
    }
  }
`;

interface RecommendationCommunityWidgetsProps {
  communityId: number;
  widget: number;
  availableWidgets: number[];
  filters: RecommendationCommunityFilterType;
  onWidgetChange: (widget: number) => void;
}

const RecommendationCommunityWidgets = ({
  communityId,
  widget,
  availableWidgets,
  filters,
  onWidgetChange,
}: RecommendationCommunityWidgetsProps) => {
  const handleWidgetChange = (widget: number) => () => {
    onWidgetChange(widget);
  };

  const notImplemented = (name: string) => () => {
    console.log(`Not implemented: ${name}`);
  };

  const { data: community } = useQuery({
    queryKey: ['community', communityId],
    queryFn: () => getCommunity(communityId),
    refetchOnWindowFocus: false,
    enabled: Boolean(communityId),
  });

  const { data: competitors } = useQuery({
    queryKey: ['competitors', communityId],
    queryFn: () => getCompetitors(communityId),
    refetchOnWindowFocus: false,
    enabled: Boolean(communityId),
  });

  return (
    <Container>
      <Menu>
        {availableWidgets.map((w) => (
          <Button
            key={w}
            type={w === widget ? 'primary' : 'default'}
            size="small"
            shape="round"
            onClick={handleWidgetChange(w)}
          >
            {WIDGET_TYPES_LABELS[w]}
          </Button>
        ))}
      </Menu>
      <CommunityViewContext.Provider
        value={{
          contentRef: undefined,
          viewId: 0,
          view: undefined,
          viewFilters: undefined,
          filtersModified: false,
          communityId: communityId,
          community: community,
          competitorId: undefined,
          competitors: competitors ?? [],
          apartmentTypes: [],
          setView: notImplemented('setView'),
          showViewModal: '',
          isViewCMA: false,
          setShowViewModal: notImplemented('setShowViewModal'),
          setViewFilters: notImplemented('setViewFilters'),
          viewRefetch: notImplemented('viewRefetch'),
          preview: false,
        }}
      >
        <Widget
          name={WIDGET_TYPES_LABELS[widget]}
          type={widget}
          note=""
          filters={getWidgetFilter(
            true,
            {
              careTypes: filters.careTypes,
              roomTypes: filters.unitTypes,
              competitors: [],
              careLevels: [],
              reviewSources: [],
              reviewCategories: [],
            },
            null
          )}
        />
      </CommunityViewContext.Provider>
    </Container>
  );
};

export default RecommendationCommunityWidgets;
