import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { CMA_SUMMARY_INITIAL_FILTERS } from 'components/CMASummary/constants';
import { useCMASummaryData } from 'components/CMASummary/customHooks';
import { transformCMASummaryFiltersInbound } from 'components/CMASummary/filterUtils';
import { CMASummaryFilters } from 'components/CMASummary/types';
import { isViewCMA } from 'components/CMASummary/utils';
import { handleSetReviewCategories } from 'store/slices/globalSlice';

import { CommunityViewContext } from './CommunityViewContext';
import CommunityViewLayout from './CommunityViewLayout';
import { DEFAULT_FILTERS, VIEW_CLASSNAME } from './constants';
import CommunityViewHeader from './header/CommunityViewHeader';
import { ViewFilters } from './types';
import { equalFilters, getFiltersFromUrl, transformFilterInbound } from './utils';
import { getCommunity, getCompetitors } from '../../apis/CommunityAPI';
import { getCategories } from '../../apis/ReviewsAPI';
import { getApartmentTypes } from '../../apis/SettingsAPI';
import { getView } from '../../apis/ViewAPI';
import { useAppDispatch } from '../../store/hook';
import { useRootContext } from '../layout/RootContext';
import Loader from '../lib/Loader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: start;
`;

const ExpandedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 100%;
`;

interface Props {
  communityId: number;
  competitorId?: number;
  viewId: number;
  preview?: boolean;
  fallbackUrl: string;
}

const CommunityView = ({ communityId, competitorId, viewId, preview = false, fallbackUrl }: Props) => {
  const navigate = useNavigate();
  const { showMessage } = useRootContext();
  const dispatch = useAppDispatch();

  const contentRef = useRef<HTMLDivElement>(null);

  const [showViewModal, setShowViewModal] = useState<string>('');
  const [view, setView] = useState<any>(undefined);
  const [viewFilters, setViewFilters] = useState<ViewFilters | CMASummaryFilters | undefined>();
  const [filtersModified, setFiltersModified] = useState<boolean>(false);

  const { data: reviewCategoriesList } = useQuery({
    queryKey: ['constant', 'categories'],
    queryFn: () => getCategories(),
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    dispatch(handleSetReviewCategories(reviewCategoriesList));
  }, [reviewCategoriesList]);

  const { data: community } = useQuery({
    queryKey: ['community', communityId],
    queryFn: () => getCommunity(communityId),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  const { data: competitors } = useQuery({
    queryKey: ['competitors', communityId],
    queryFn: () => getCompetitors(communityId),
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  const { data: apartmentTypes } = useQuery({
    queryKey: ['apartmentTypes', community, competitors],
    queryFn: () => {
      return getApartmentTypes({ communities: [communityId, ...(competitors ?? []).map((item: any) => item.id)] });
    },
    refetchOnWindowFocus: false,
    enabled: communityId > 0,
  });

  const {
    data: viewData,
    isLoading: isViewLoading,
    isFetched: isViewFetched,
    isError,
    refetch: viewRefetch,
  } = useQuery({
    queryKey: ['view', viewId, communityId, competitorId],
    queryFn: () => getView(viewId),
    enabled: !!viewId && viewId > 0,
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (viewData) {
      setView(viewData);
      if (isViewCMA(viewData)) {
        if (viewData.filter) {
          const filtersData: CMASummaryFilters = transformCMASummaryFiltersInbound(viewData.filter);
          setViewFilters(filtersData);
        } else {
          setViewFilters(CMA_SUMMARY_INITIAL_FILTERS);
        }
      } else {
        let transformedFilters = transformFilterInbound(viewData?.filter);
        const urlFilters = getFiltersFromUrl();
        if (urlFilters) {
          transformedFilters = urlFilters;
        }

        if (competitorId) {
          setViewFilters((prevState: any) => Object.assign({}, transformedFilters, { competitors: [competitorId] }));
        } else {
          setViewFilters(transformedFilters);
        }
      }
    } else if (isViewFetched && isError) {
      showMessage('error', 'View could not be found!');
      navigate(fallbackUrl);
    }
  }, [viewData, isViewFetched, isError]);

  useEffect(() => {
    if (view && viewFilters) {
      setFiltersModified(!equalFilters(view.filter, viewFilters));
    }
  }, [viewFilters]);

  useEffect(() => {
    if (viewData && !viewFilters) {
      if (isViewCMA(viewData)) {
        setViewFilters((prevState: any) => ({ ...prevState, CMA_SUMMARY_INITIAL_FILTERS }));
      } else {
        let filters = { ...DEFAULT_FILTERS };
        const urlFilters = getFiltersFromUrl();
        if (urlFilters) {
          filters = Object.assign({}, filters, urlFilters);
        }
        setViewFilters(filters);
      }
    }
  }, [viewData]);

  const { CMASummaryData, isCMADataLoading, isCMADataFetched } = useCMASummaryData({
    community,
    competitors,
    filters: viewFilters as CMASummaryFilters,
  });

  const isFetched = isViewFetched && isCMADataFetched;
  const isLoading = isViewLoading || isCMADataLoading;

  return (
    <CommunityViewContext.Provider
      value={{
        contentRef,
        viewId,
        view,
        viewFilters,
        filtersModified,
        communityId,
        community,
        competitorId,
        competitors,
        apartmentTypes,
        setView,
        showViewModal,
        isViewCMA: isViewCMA(viewData),
        CMASummaryData,
        setShowViewModal,
        setViewFilters,
        viewRefetch,
        preview,
      }}
    >
      <Container>
        <Loader active={isLoading} />
        {isFetched && (
          <ExpandedContainer ref={contentRef} className={VIEW_CLASSNAME}>
            <CommunityViewHeader />
            <CommunityViewLayout data={viewData} />
          </ExpandedContainer>
        )}
      </Container>
    </CommunityViewContext.Provider>
  );
};

export default CommunityView;
