import React, { useEffect } from 'react';

import styled from 'styled-components';

import { useNavigationContext } from 'components/Beta/NavigationContext';

import SidebarSelector from './Selector/SidebarSelector';
import SidebarLogo from './SidebarLogo';
import SidebarMenu from './SidebarMenu';

const Container = styled.div`
  min-width: 210px;
  max-width: 210px;
  background: linear-gradient(to top, var(--sidebar-purple-dark) 20%, var(--sidebar-purple-light));

  @media (max-width: 768px) {
    display: none;
  }

  &.mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 6;
      width: 300px;
      max-width: 300px;
    }
  }
`;

const StyledCloseButton = styled.div`
  display: none;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 27px;
  left: 12px;
  cursor: pointer;

   @media (max-width: 768px) {
    display: block;
  }
`;

type Props = {
  className?: string;
}

const Sidebar = ({ className }: Props) => {
  const { closeMobileSidebar } = useNavigationContext();

  return (
    <Container className={className}>
      <StyledCloseButton onClick={closeMobileSidebar}>
        <img src="/icons/close-gray.svg" width={24} height={24} />
      </StyledCloseButton>
      <SidebarLogo />
      <SidebarSelector />
      <SidebarMenu />
    </Container>
  );
};

export default Sidebar;
