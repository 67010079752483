import { useEffect, useState } from 'react';

import {
  ANTD_TABLE_CONTAINER_CLASS,
  ANTD_TABLE_HEADER_CLASS,
  DYNAMIC_COLUMNS_TABLE_COLUMN_WIDTH,
  INITIAL_NUMBER_OF_COLUMNS,
} from './constants';

export const useSetNumberOfDisplayedColumns = (
  tableRef: React.RefObject<HTMLInputElement>,
  onResetCallback: (i: number) => void
) => {
  const [currentNumber, setCurrentNumber] = useState(INITIAL_NUMBER_OF_COLUMNS);

  const resetNumberOfDisplayedCommunities = () => {
    if (!tableRef?.current) {
      return;
    }

    const tableHeader: HTMLElement | null = tableRef.current.querySelector(`.${ANTD_TABLE_HEADER_CLASS}`);
    if (!tableHeader) {
      return;
    }

    const numberOfCommunitiesToFit = Math.floor(tableHeader.offsetWidth / DYNAMIC_COLUMNS_TABLE_COLUMN_WIDTH);
    if (numberOfCommunitiesToFit !== currentNumber) {
      onResetCallback(numberOfCommunitiesToFit);
      setCurrentNumber(numberOfCommunitiesToFit);
    }
  };

  useEffect(() => {
    resetNumberOfDisplayedCommunities();
  }, [tableRef, currentNumber, resetNumberOfDisplayedCommunities]);

  useEffect(() => {
    window.addEventListener('resize', resetNumberOfDisplayedCommunities);
    return () => {
      window.removeEventListener('resize', resetNumberOfDisplayedCommunities);
    };
  }, []);
};

export const useCMASummaryTableStickyHeader = (enabled: boolean, competitorsMaxIndex: number) => {
  const [containerWidth, setContainerWidth] = useState('0px');
  const [containerHeight, setContainerHeight] = useState('0px');

  // Initial width calculation
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const tableHeaderContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_HEADER_CLASS}`);
    if (!tableHeaderContainer) {
      return;
    }
    const placeholder = document.createElement('div');
    placeholder.id = 'fixed-header-placeholder';
    placeholder.style.display = 'none';
    tableHeaderContainer.parentNode?.insertBefore(placeholder, tableHeaderContainer);

    const tableHeaderWidth = `${tableHeaderContainer.offsetWidth}px`;
    const tableHeaderHeight = `${tableHeaderContainer.offsetHeight}px`;
    setContainerWidth(tableHeaderWidth);
    setContainerHeight(tableHeaderHeight);

    return () => {
      if (placeholder && placeholder.parentNode) {
        placeholder.parentNode.removeChild(placeholder);
      }
    };
  }, [competitorsMaxIndex, enabled, setContainerWidth, setContainerHeight]);

  // Recalculate header width on resize
  useEffect(() => {
    const resizeTableHeader = () => {
      const tableContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_CONTAINER_CLASS}`);
      const tableHeaderContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_HEADER_CLASS}`);

      if (tableContainer && tableHeaderContainer) {
        const tableBodyWidth = `${tableContainer.offsetWidth}px`;
        const tableHeaderHeight = `${tableHeaderContainer.offsetHeight}px`;
        setContainerWidth(tableBodyWidth);
        setContainerHeight(tableHeaderHeight);

        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const initialOffsetTop = tableContainer.getBoundingClientRect().top + window.scrollY;
        if (scrollTop > initialOffsetTop) {
          const placeholder: HTMLElement | null = document.querySelector('#fixed-header-placeholder');
          if (!tableHeaderContainer || !placeholder) {
            return;
          }
          placeholder.style.width = tableBodyWidth;
          placeholder.style.display = 'block';

          tableHeaderContainer.style.width = tableBodyWidth;
        }
      }
    };

    if (enabled) {
      window.addEventListener('resize', resizeTableHeader);
    }
    return () => {
      if (enabled) {
        window.removeEventListener('resize', resizeTableHeader);
      }
    };
  }, [enabled]);

  // handle moving of fixed header on scroll
  useEffect(() => {
    const handleScroll = () => {
      const tableHeaderContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_HEADER_CLASS}`);
      const placeholder: HTMLElement | null = document.querySelector('#fixed-header-placeholder');
      const tableContainer: HTMLElement | null = document.querySelector(`.${ANTD_TABLE_CONTAINER_CLASS}`);
      if (!tableContainer || !placeholder || !tableHeaderContainer) {
        return;
      }

      let mobileTopbarHeight = 0;
      const mobileTopBar = document.getElementById("mobile-top-bar");
      if (mobileTopBar) {
        mobileTopbarHeight = mobileTopBar.offsetHeight;
      }

      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const initialOffsetTop = tableContainer.getBoundingClientRect().top + window.scrollY - mobileTopbarHeight;
      if (scrollTop > initialOffsetTop) {
        placeholder.style.width = containerWidth;
        placeholder.style.height = containerHeight;
        placeholder.style.display = 'block';

        tableHeaderContainer.style.position = 'fixed';
        tableHeaderContainer.style.top = `${mobileTopbarHeight}px`;
        tableHeaderContainer.style.zIndex = '1';
        tableHeaderContainer.style.width = containerWidth;
      } else {
        tableHeaderContainer.style.position = '';
        tableHeaderContainer.style.top = '';
        tableHeaderContainer.style.width = '';

        placeholder.style.display = 'none';
      }
    };

    if (enabled) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (enabled) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerWidth, containerHeight, enabled]);
};
