import type { Reducer } from "@reduxjs/toolkit";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import * as rp from "redux-persist";
import { persistReducer, persistStore } from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import storage from "redux-persist/lib/storage";

// reducers
import auth from "./slices/authSlice";
import community from "./slices/communitySlice";
import global from "./slices/globalSlice";
import sub_community from "./slices/subCommunitySlice";

export const PERSIST_STORE_LOCAL_STORAGE_SELECTOR = 'persist:further';

const persistConfig = {
  key: "further",
  whitelist: ["auth", "global"],
  storage,
  stateReconciles: hardSet as (inboundState: CombinedState) => CombinedState,
  version: 1,
};

type CombinedState = typeof rootReducer extends Reducer<infer U, any>
  ? U
  : never;

const rootReducer = combineReducers({
  auth,
  global,
  community,
  sub_community,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          rp.FLUSH,
          rp.REHYDRATE,
          rp.PAUSE,
          rp.PERSIST,
          rp.PURGE,
          rp.REGISTER,
        ],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
