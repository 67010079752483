import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import { Form, FormInstance } from 'antd';

import { getCareFees, getFeesIncentives, getIncentives, getLivingInfo, getOccupancies } from 'apis/CommunityAPI';
import { fetchData } from 'apis/DataAPI';
import { WIDGET_TYPES } from 'common/Widgets/constants';
import { DATA_RANGE_TYPE } from 'common/Widgets/Widget/constants';
import { mapCommunityLivingInfoToData } from 'pages/price_healing/Accommodation/utils';
import { mapCommunityOccupancyResponseToData } from 'pages/price_healing/Occupancies/utils';
import { useAppSelector } from 'store/hook';

import { CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS } from './constants';
import { CMASummaryFilters } from './types';
import { getCMASummaryData } from './utils';
import { mapCareFeeResponseToData, mapCommunityFeeResponseToData } from '../../pages/price_healing/Fees/utils';

const useFetchCMASummaryData = (community: any, competitors: any[], filters: CMASummaryFilters) => {
  const {
    data: ratings,
    isLoading: ratingsLoading,
    isFetched: isRatingsFetched,
  } = useQuery({
    queryKey: ['CMASummary', 'ratings', community?.id, filters?.reviewSources, competitors],
    queryFn: () => {
      const params: any = {
        review_sources: filters?.reviewSources,
      };
      return fetchData(community?.id, WIDGET_TYPES.RATING_GRAPH, DATA_RANGE_TYPE.NOW, params);
    },
    select: (data?: any[]) => {
      // fetchData endpoint doesnt always give ratings in the right order of competitors.
      // therefore we need to sort it according to our community&competitor list.
      return [community, ...competitors].map((community: any) =>
        data?.find((communityRating: any) => communityRating.community_id === community.id)
      );
    },
    enabled: Boolean(community),
    refetchOnWindowFocus: false,
  });

  const {
    data: feesIncentives,
    isLoading: feesLoading,
    isFetched: isFeesIncentivesFetched,
  } = useQuery({
    queryKey: ['CMASummary', 'feesIncentive', community?.id, competitors],
    queryFn: () => {
      const fetchAllFeesIncentives = async (community: any, competitors: any[]) => {
        const requests = [getFeesIncentives(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getFeesIncentives(competitor.id, { active: 1 }));
        });

        const responses = await Promise.all(requests);
        return responses.map((response) => response?.map((item: any) => mapCommunityFeeResponseToData(item)));
      };

      return fetchAllFeesIncentives(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const {
    data: careFees,
    isLoading: careFeesLoading,
    isFetched: isCareFeesFetched,
  } = useQuery({
    queryKey: ['CMASummary', 'careFees', community?.id, competitors],
    queryFn: () => {
      const fetchAllCareFees = async (community: any, competitors: any[]) => {
        const requests = [getCareFees(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getCareFees(competitor.id, { active: 1 }));
        });

        const responses = await Promise.all(requests);
        return responses.map((response) => response?.map((item: any) => mapCareFeeResponseToData(item)));
      };

      return fetchAllCareFees(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const {
    data: occupancies,
    isLoading: occupanciesLoading,
    isFetched: isOccupanciesFetched,
  } = useQuery({
    queryKey: ['CMASummary', 'occupancies', community?.id, competitors],
    queryFn: () => {
      const fetchAllOccupancies = async (community: any, competitors: any[]) => {
        const requests = [getOccupancies(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getOccupancies(competitor.id, { active: 1 }));
        });
        const responses = await Promise.all(requests);
        return responses.map((response) =>
          response.map((occupancy: any) => mapCommunityOccupancyResponseToData(occupancy))
        );
      };

      return fetchAllOccupancies(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const {
    data: incentives,
    isLoading: incentivesLoading,
    isFetched: isIncetivesFetched,
  } = useQuery({
    queryKey: ['CMASummary', 'incentives', community?.id, competitors, filters?.careTypes],
    queryFn: () => {
      const fetchAllIncentives = async (community: any, competitors: any[]) => {
        const filterParams = { status: 1, care_types: filters?.careTypes?.length ? filters?.careTypes : undefined };
        const requests = [getIncentives(community.id, { ...filterParams, competitors: [community.id] })];
        competitors.forEach((competitor) => {
          requests.push(getIncentives(competitor.id, { ...filterParams, competitors: [competitor.id] }));
        });
        const responses = await Promise.all(requests);
        return responses;
      };

      return fetchAllIncentives(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const {
    data: livingInfo,
    isLoading: livingInformationLoading,
    isFetched: isLivingInfoFetched,
  } = useQuery({
    queryKey: ['CMASummary', 'livingInfo', community?.id, competitors],
    queryFn: () => {
      const fetchAllLivingInformation = async (community: any, competitors: any[]) => {
        const requests = [getLivingInfo(community.id, { active: 1 })];
        competitors.forEach((competitor) => {
          requests.push(getLivingInfo(competitor.id, { active: 1 }));
        });
        const responses = await Promise.all(requests);
        return responses.map((response) => response.map((livingInfo: any) => mapCommunityLivingInfoToData(livingInfo)));
      };

      return fetchAllLivingInformation(community, competitors);
    },
    enabled: Boolean(community && competitors),
    refetchOnWindowFocus: false,
  });

  const loading =
    ratingsLoading ||
    feesLoading ||
    careFeesLoading ||
    livingInformationLoading ||
    occupanciesLoading ||
    incentivesLoading;

  const isFetched =
    isLivingInfoFetched &&
    isIncetivesFetched &&
    isIncetivesFetched &&
    isOccupanciesFetched &&
    isCareFeesFetched &&
    isFeesIncentivesFetched &&
    isRatingsFetched;

  return { loading, isFetched, ratings, feesIncentives, incentives, careFees, occupancies, livingInfo };
};

export const useFocusNewestRow = (
  roomComparisonType: number,
  roomComparisonRows: any[],
  containerRef: React.RefObject<HTMLDivElement>,
  numberOfRows: number,
  setNumberOfRows: (number: number) => void
) => {
  useEffect(() => {
    if (roomComparisonType === CMA_SUMMARY_ROOM_COMPARISON_TYPE_FILTER_OPTIONS.ADVANCED_COMPARISON) {
      if (!roomComparisonRows?.length) {
        return;
      }

      if (roomComparisonRows.length > numberOfRows) {
        // focus the newly added filter row
        if (containerRef.current) {
          const allInputFields = containerRef?.current?.querySelectorAll('input');
          if (allInputFields.length) {
            allInputFields[allInputFields.length - 1].focus();
          }
        }
      }
      setNumberOfRows(roomComparisonRows.length);
    } else {
      setNumberOfRows(0);
    }
  }, [roomComparisonRows, roomComparisonType]);
};

/**
 * This hook will make sure that:
 * 1. Once the parent `careTypes` checkbox is unchecked, that all of the children checboxes are unchecked as well
 * 2. If `careTypes` checbox is unchecked, and then any of the children are checked -> make the `careTypes` checked
 */
export const useCareTypesCheckboxSelector = ({ form }: { form: FormInstance<any> }) => {
  const careTypes = Form.useWatch('careTypes', form);
  const roomType = Form.useWatch('roomType', form);
  const communityFee = Form.useWatch('communityFee', form);
  const petFee = Form.useWatch('petFee', form);
  const secondPersonFee = Form.useWatch('secondPersonFee', form);
  const careFee = Form.useWatch('careFee', form);

  useEffect(() => {
    if (!careTypes) {
      form.setFieldsValue({
        roomType: false,
        communityFee: false,
        petFee: false,
        secondPersonFee: false,
        careFee: false,
      });
    }
  }, [careTypes]);

  useEffect(() => {
    if (!careTypes && roomType) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [roomType]);
  useEffect(() => {
    if (!careTypes && communityFee) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [communityFee]);
  useEffect(() => {
    if (!careTypes && petFee) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [petFee]);
  useEffect(() => {
    if (!careTypes && secondPersonFee) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [secondPersonFee]);
  useEffect(() => {
    if (!careTypes && careFee) {
      form.setFieldsValue({ careTypes: true });
    }
  }, [careFee]);
};

export const useCMASummaryData = ({
  community,
  competitors,
  filters,
}: {
  community: any;
  competitors: any[];
  filters: CMASummaryFilters;
}) => {
  const { livingtypes: livingTypes, apartmentTypeGroups } = useAppSelector((state) => state.global);

  const filteredCompetitors = filters?.competitors?.length
    ? competitors?.filter((competitor) => filters?.competitors.includes(competitor.id))
    : competitors;

  const {
    loading: isCMADataLoading,
    isFetched: isCMADataFetched,
    ratings,
    feesIncentives,
    incentives,
    careFees,
    occupancies,
    livingInfo,
  } = useFetchCMASummaryData(community, filteredCompetitors || [], filters);

  const CMASummaryData = getCMASummaryData({
    community,
    competitors: filteredCompetitors,
    ratings,
    livingTypes,
    feesIncentives,
    incentives,
    careFees,
    occupancies,
    livingInfo,
    apartmentTypeGroups,
    filters,
  });

  return { CMASummaryData, isCMADataLoading, isCMADataFetched };
};
