import React from 'react';
import { useQuery } from 'react-query';

import dayjs from 'dayjs';

import { CommunityBaseIncentiveType, CommunityIncentiveType } from './types';
import {
  createIncentive,
  deleteIncentive,
  getIncentives,
  updateIncentive,
  uploadIncentiveFiles,
} from '../../apis/CommunityAPI';
import { EditableTable } from '../../common/Table';
import { useAppSelector } from '../../store/hook';
import { useRootContext } from '../layout/RootContext';

interface Props {
  communityId: number;
}

const CommunityIncentivesTable = ({ communityId }: Props) => {
  const { showMessage } = useRootContext();
  const living_types = useAppSelector((state) => state.global.livingtypes);

  const living_options = living_types.map((val) => ({
    label: val.type,
    value: val.id,
  }));

  // Passing empty competitors parameter to filter our records for related communities.
  // Passing parent community as filter for competitors engages filter with condition only current community satisfies
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['community', 'data', 'incentives', communityId],
    queryFn: () => getIncentives(communityId as number, { status: 1, competitors: [communityId] }),
    select: (data: CommunityBaseIncentiveType[]) => {
      return data.map((item) => ({
        ...item,
        key: String(item.id),
        createdAt: item.created_at,
        createdBy: item.created_by ? item.created_by.full_name ?? item.created_by.email : undefined,
        updatedAt: item.updated_at,
        updatedBy: item.updated_by ? item.updated_by.full_name ?? item.updated_by.email : undefined,
        images_ids: (item.images ?? []).map((item: any) => item.id),
        files_ids: (item.files ?? []).map((item: any) => item.id),
        date: dayjs(item.date),
      }));
    },
    onError: () => {
      showMessage('error', 'Failed to fetch Community Incentive');
    },
    enabled: Boolean(communityId),
    refetchOnWindowFocus: false,
  });

  const uploadFiles = async (item: Partial<CommunityIncentiveType>[]) => {
    // Perform upload of newly added files
    const formData = new FormData();

    formData.append('incentives', JSON.stringify(item));
    item.forEach((incentive, index) => {
      (incentive.files_upload ?? []).forEach((file: any) => formData.append(`files_${index}`, file.originFileObj));
      (incentive.images_upload! ?? []).forEach((file: any) => formData.append(`images_${index}`, file.originFileObj));
    });

    await uploadIncentiveFiles(communityId as number, formData).catch((reason) => {
      console.error(reason);
      showMessage('error', 'Failed to upload Community Incentive files!');
    });
  };

  const onCreate = async (item: Partial<CommunityIncentiveType>) => {
    await createIncentive(communityId, { ...item, community: communityId, files: [], images: [] })
      .then(async (response: any) => {
        await uploadFiles([{ id: response.id, ...item }]);
        return response;
      })
      .then((response) => {
        refetch();
        showMessage('success', 'Community Incentive added successfully');
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to add Community Incentive');
      });
  };

  const onUpdate = async (item: Partial<CommunityIncentiveType>) => {
    await updateIncentive(communityId as number, item.id as number, {
      ...item,
      community: communityId,
      files: item.files_ids,
      images: item.images_ids,
    })
      .then(async (response: any) => {
        await uploadFiles([{ id: item.id as number, ...item }]);
        return response;
      })
      .then((response: any) => {
        refetch();
        showMessage('success', 'Community Incentive changed successfully');
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to change Community Incentive');
      });
  };

  const onDelete = async (item: Partial<CommunityIncentiveType>) => {
    await deleteIncentive(communityId as number, item.id as number)
      .then((response: any) => {
        refetch();
        showMessage('success', 'Community Incentive deleted successfully');
      })
      .catch((reason: any) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Community Incentive');
      });
  };

  const livingTypesValidator = async (record: CommunityIncentiveType) => {
    const hasLivingType = Boolean(record?.living_types?.length);
    if (!hasLivingType) {
      return Promise.reject('Must assign at least on Living Type!');
    }
    return Promise.resolve();
  };

  const columns = [
    {
      title: 'Living Types',
      dataIndex: 'living_types',
      inputType: 'multiple_select',
      options: living_options,
      editable: true,
      width: '200px',
      validator: livingTypesValidator,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      inputType: 'currency',
      editable: true,
      width: '130px',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      inputType: 'textArea',
      editable: true,
      required: true,
    },
    {
      title: 'Files Attachments',
      dataIndex: 'files_ids',
      inputType: 'attachment',
      editable: true,
    },
    {
      title: 'Images Attachments ',
      dataIndex: 'images_ids',
      inputType: 'attachment',
      editable: true,
    },
    {
      title: 'Data Collected Date',
      dataIndex: 'date',
      inputType: 'date',
      editable: true,
      width: '200px',
    },
  ];

  return (
    <div>
      <EditableTable
        tableLayout="fixed"
        loading={isLoading}
        columns={columns}
        data={data ?? []}
        onAdd={onCreate}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
    </div>
  );
};

export default CommunityIncentivesTable;
