import React from 'react';

import styled from 'styled-components';

import Loader from 'components/lib/Loader';

import { useShopDataModalContext } from './ShopDataModalContext';
import ShopDataModalFooter from './ShopDataModalFooter';

const Container = styled.div`
  flex: 1;
`;
const Content = styled.div`
  min-height: 350px;
`;

const ShopDataModalContent = () => {
  const { selectedItem } = useShopDataModalContext();
  return (
    <Container>
      <Content>
        <React.Suspense fallback={<Loader active={true} />}>{selectedItem.component}</React.Suspense>
      </Content>
      <ShopDataModalFooter />
    </Container>
  );
};

export default ShopDataModalContent;
