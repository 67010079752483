import React from 'react';

import styled from 'styled-components';

import CareTypeFilter from 'common/Filters/CareTypeFilter';
import CompetitorsFilter from 'common/Filters/CompetitorsFilter';
import ReviewSourceFilter from 'common/Filters/ReviewSourceFilter';
import RoomTypeFilter from 'common/Filters/RoomTypeFilter';

import HeaderViewFiltersCareLevel from './HeaderViewFiltersCareLevel';
import HeaderViewFiltersCompare from './HeaderViewFiltersCompare';
import HeaderViewFiltersReviewCategory from './HeaderViewFiltersReviewCategory';
import HeaderViewFiltersSwotCategory from './HeaderViewFiltersSwotCategory';
import HeaderViewFiltersTime from './HeaderViewFiltersTime';
import { useCommunityViewContext } from '../CommunityViewContext';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  gap: 5px;
`;

const HeaderViewFiltersMenu = () => {
  const { view, competitorId, competitors, viewFilters } = useCommunityViewContext();

  const competitorsFilterOptions = competitors?.map((item) => ({
    label: item.comunity_name,
    value: item.id,
    color: item.color,
  }));

  return (
    <Container>
      <HeaderViewFiltersTime />
      {competitorId && <HeaderViewFiltersCompare />}
      {!competitorId && (
        <CompetitorsFilter competitors={viewFilters?.competitors || []} options={competitorsFilterOptions} />
      )}
      {view?.default && (
        <>
          <CareTypeFilter careTypes={viewFilters?.careTypes} />
          <RoomTypeFilter roomTypes={viewFilters?.roomTypes} />
          <HeaderViewFiltersCareLevel />
          <ReviewSourceFilter reviewSources={viewFilters?.reviewSources} />
          <HeaderViewFiltersReviewCategory />
          <HeaderViewFiltersSwotCategory />
        </>
      )}
    </Container>
  );
};

export default HeaderViewFiltersMenu;
