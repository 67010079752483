import React, { useState } from 'react';

import styled from 'styled-components';

import ConfirmModal from 'common/Modal/ConfirmModal';
import { useAppSelector } from 'store/hook';

import { useCommunityIncentivesContext } from './CommunityIncentivesContext';
import CommunityIncentivesModal from './CommunityIncentivesModal';
import { CommunityIncentivesType } from './types';
import { deleteIncentive, updateIncentive, uploadIncentiveFiles } from '../../apis/CommunityAPI';
import DownCaretIcon from '../../common/Icons/DownCaretIcon';
import { useRootContext } from '../layout/RootContext';
import Button from '../lib/Button';
import Dropdown from '../lib/Dropdown';

type Props = {
  item: CommunityIncentivesType;
};

const Wrapper = styled.div`
  visibility: hidden;
`;

const CommunityIncentivesTableActions = ({ item }: Props) => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { refetch } = useCommunityIncentivesContext();
  const { showMessage } = useRootContext();

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const handleDeleteIncentive = async () => {
    await deleteIncentive(selectedCommunity?.id as number, item.id as number)
      .then((response: any) => {
        refetch();
        showMessage('success', 'Community Incentive deleted successfully');
      })
      .catch((reason: any) => {
        console.error(reason);
        showMessage('error', 'Failed to delete Community Incentive');
      });
  };

  const onUpdate = async (values: CommunityIncentivesType, files: any[]) => {
    await updateIncentive(selectedCommunity?.id as number, item?.id as number, {
      ...values,
      community: selectedCommunity?.id,
      files: files?.map((item: any) => item.id),
    })
      .then(async (response: any) => {
        await uploadFiles([{ id: item?.id as number, ...values }]);
        return response;
      })
      .then((response: any) => {
        refetch();
        showMessage('success', 'Community Incentive updated successfully');
        setOpenEditModal(false);
      })
      .catch((reason) => {
        console.error(reason);
        showMessage('error', 'Failed to update Community Incentive');
      });
  };

  const uploadFiles = async (incentives: CommunityIncentivesType[]) => {
    const formData = new FormData();
    formData.append('incentives', JSON.stringify(incentives));
    incentives.forEach((incentive, index) => {
      (incentive.files_upload ?? []).forEach((file: any) => formData.append(`files_${index}`, file.originFileObj));
    });

    await uploadIncentiveFiles(selectedCommunity?.id as number, formData).catch((reason) => {
      console.error(reason);
      showMessage('error', 'Failed to upload Community Incentive files!');
    });
  };

  return (
    <Wrapper className={'hover-visible'}>
      <ConfirmModal
        open={openDeleteModal}
        title={'Delete Incentives'}
        description={`This will delete the ${selectedCommunity?.type} incentive.\nAre you sure you want to do this?`}
        confirmLabel="Delete"
        onConfirm={handleDeleteIncentive}
        onCancel={() => setOpenDeleteModal(false)}
      />
      <CommunityIncentivesModal
        openModal={openEditModal}
        submitButtonText="Save"
        title="Edit Incentive"
        onSubmit={onUpdate}
        onClose={() => setOpenEditModal(false)}
        incentive={item}
      />
      <Dropdown
        menu={{
          items: [
            {
              label: 'Edit Incentive',
              key: 'editIncentive',
              onClick: () => setOpenEditModal(true),
            },
            {
              label: 'Delete Incentive',
              key: 'deleteIncentive',
              onClick: () => setOpenDeleteModal(true),
            },
          ],
        }}
      >
        <Button style={{ width: 100 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <span>Actions</span>
            <DownCaretIcon />
          </div>
        </Button>
      </Dropdown>
    </Wrapper>
  );
};

export default CommunityIncentivesTableActions;
