import React from 'react';

import { WIDGET_MODAL_TYPE } from './constants';
import { useWidgetContext } from './WidgetContext';
import CommunityViewWidgetModal from '../../../components/View/create/CommunityViewWidgetModal';

const WidgetEditModal = () => {
  const {
    id,
    type,
    initialRange,
    initialFormat,
    name,
    note,
    filters,
    showEditWidgetModal,
    setShowEditWidgetModal,
  } = useWidgetContext();

  const handleClose = () => {
    setShowEditWidgetModal(false);
  };

  return (
    <CommunityViewWidgetModal
      open={showEditWidgetModal}
      onClose={handleClose}
      modalType={WIDGET_MODAL_TYPE.EDIT}
      widget={{
        id,
        type,
        name,
        note,
        initial_settings: {
          range: initialRange,
          format: initialFormat,
        },
        filters,
      }}
    />
  );
};

export default WidgetEditModal;
