import React from 'react';

import styled from 'styled-components';

import Button from 'components/lib/Button';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  height: 100%;
`;

const Label = styled.div`
  overflow: hidden;
  max-width: 180px;
  word-break: break-word;
`;

type Props = {
  index: number;
  community: any;
  filteredCompetitorsCount: number;
  totalCompetitorsCount: number;
  disableLeft: boolean;
  disableRight: boolean;
  displayedNumberOfCompetitors: number;
  scrollLeft: () => void;
  scrollRight: () => void;
};

const DynamicColumnsTableColumnHeader = ({
  index,
  community,
  filteredCompetitorsCount,
  totalCompetitorsCount,
  disableLeft,
  disableRight,
  displayedNumberOfCompetitors,
  scrollLeft,
  scrollRight,
}: Props) => {
  let leftButton, rightButton;
  if (totalCompetitorsCount > displayedNumberOfCompetitors) {
    leftButton = (
      <ButtonContainer style={{ left: '-30px' }}>
        <StyledButton disabled={disableLeft} onClick={scrollLeft}>
          <img src="/icons/chevron-left.svg" style={{ width: '24px', height: '24px' }} />
        </StyledButton>
      </ButtonContainer>
    );
    rightButton = (
      <ButtonContainer style={{ right: '0px' }}>
        <StyledButton disabled={disableRight} onClick={scrollRight}>
          <img src="/icons/chevron-right.svg" style={{ width: '24px', height: '24px' }} />
        </StyledButton>
      </ButtonContainer>
    );
  }

  return (
    <Container title={community?.comunity_name}>
      {index === 0 && leftButton}
      <Label className="column-header-label">{community?.comunity_name}</Label>
      {index === filteredCompetitorsCount - 1 && rightButton}
    </Container>
  );
};

export default DynamicColumnsTableColumnHeader;
