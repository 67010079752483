import React from 'react';

const ShopDataModalIncentives = React.lazy(() => import('./ShopDataModalIncentives'));
import { ShopDataModalSidebarMenuItemsType } from './types';
const ShopDataModalAmenities = React.lazy(() => import('./ShopDataModalAmenities'));
const ShopDataModalShopNotes = React.lazy(() => import('./ShopDataModalShopNotes'));
const ShopDataModalAdditionalLinks = React.lazy(() => import('./ShopDataModalAdditionalLinks'));
const ShopDataModalBasicInfo = React.lazy(() => import('./ShopDataModalBasicInfo'));
const ShopDataModalPhotosBrochuresDocs = React.lazy(
  () => import('./PhotosBrochuresDocs/ShopDataModalPhotosBrochuresDocs')
);
const ShopDataModalOccupanciesRateTrends = React.lazy(
  () => import('./OccupanciesRateTrends/ShopDataModalOccupanciesRateTrends')
);

export const SHOP_DATA_MODAL_SIDEBAR_MENU_ITEMS: ShopDataModalSidebarMenuItemsType = [
  {
    index: 0,
    label: 'Incentives',
    key: 'incentives',
    component: <ShopDataModalIncentives />,
  },
  {
    index: 1,
    label: 'Photos, Brochures & Docs',
    key: 'photosBrochuresDocs',
    component: <ShopDataModalPhotosBrochuresDocs />,
  },
  {
    index: 2,
    label: 'Occupancies & Rate Trends',
    key: 'occupanciesRateTrends',
    component: <ShopDataModalOccupanciesRateTrends />,
  },
  {
    index: 3,
    label: 'Additional Links',
    key: 'additionalLinks',
    component: <ShopDataModalAdditionalLinks />,
  },
  {
    index: 4,
    label: 'Basic Info',
    key: 'basicInfo',
    component: <ShopDataModalBasicInfo />,
  },
  {
    index: 5,
    label: 'Shop Notes',
    key: 'shopNotes',
    component: <ShopDataModalShopNotes />,
  },
  {
    index: 5,
    label: 'Amenities',
    key: 'amenities',
    component: <ShopDataModalAmenities />,
  },
];

export const SHOP_DATA_MODAL_DEFAULT_APARTMENT_TYPE = 26;
export const VACANCY_TYPE_PERCENTAGE = 'percentage';
export const VACANCY_TYPE_UNIT = 'unit';
