import React from 'react';

import styled from 'styled-components';

import Select from 'components/lib/Select';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 185px;
`;

const Title = styled.div`
  color: var(--text-primary);
  font-size: 12px;
  margin-bottom: 5px;

  &.heading {
    font-family: var(--font-bold);
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

interface Props {
  title: string;
  value: string | number;
  options: any[];
  onSelect: (value: string) => void;
}

const CommunityViewWidgetModalFilterSingleSelect = ({ title, value, options, onSelect }: Props) => (
  <Container>
    <Title>{title}</Title>
    <Select style={{ height: 36 }} className={'rectangular'} value={value} options={options} onChange={onSelect} />
  </Container>
);

export default CommunityViewWidgetModalFilterSingleSelect;
