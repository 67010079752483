import React from 'react';
import { useParams } from 'react-router';

import styled from 'styled-components';

import { sortViews } from 'components/View/utils';

import { usePublicationContext } from './PublicationContext';
import SidebarMenuCategoryHeader from '../layout/Sidebar/SidebarMenuCategoryHeader';
import SidebarMenuItem from '../layout/Sidebar/SidebarMenuItem';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  background: transparent;
  font-size: 12px;
`;

const Message = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  font-size: 10px;
  color: #fff;
  opacity: 0.8;
`;

const PublicSidebarMenu = () => {
  const { hash } = useParams();
  const { views } = usePublicationContext();

  return (
    <Container>
      <SidebarMenuCategoryHeader title={'VIEWS'} />
      {views?.length === 0 && <Message>No views found</Message>}
      {views
        ?.sort(sortViews)
        .map((item: any) => <SidebarMenuItem key={item.id} to={`/public/${hash}/view/${item.id}`} label={item.name} />)}
    </Container>
  );
};

export default PublicSidebarMenu;
