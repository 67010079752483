import React, { useEffect, useState } from 'react';

import { Divider as AntDivider } from 'antd';
import styled from 'styled-components';

import Header from 'common/Header';
import { useAppSelector } from 'store/hook';
import { RecommendationCommunityOverviewType } from 'types/recommendations';

import RecommendationBreadcrumb from './RecommendationBreadcrumb';
import RecommendationCategoryTag from './RecommendationCategoryTag';
import RecommendationCommunityList from './RecommendationCommunityList';
import RecommendationCommunityListFilters from './RecommendationCommunityListFilters';
import useRecommendationDetailsContext from './RecommendationDetailsContext';
import RecommendationReasoning from './RecommendationReasoning';
import useRecommendationsContext from './RecommendationsContext';

const Container = styled.div`
  width: 100%;
`;

const TitleContainer = styled.div`
  font-size: 28px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: var(--font-regular);
`;

const ReasoningContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 10px 30px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 30px;
`;

const CategoryTagContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const Divider = styled(AntDivider)`
  margin: 20px 0px;
`;

const RecommendationDetails = () => {
  const { company } = useRecommendationsContext();
  const { selectedCommunity } = useAppSelector((state) => state.global);
  const { recommendation, filters, communityOptions, updateFilters } = useRecommendationDetailsContext();
  const [communityOverviews, setCommunityOverviews] = useState<RecommendationCommunityOverviewType[]>();

  useEffect(() => {
    if (!recommendation) {
      return;
    }

    if (filters.communities.length !== 0) {
      setCommunityOverviews(
        recommendation.community_overviews.filter((co) => filters.communities.includes(co.community_id))
      );
    } else {
      setCommunityOverviews(recommendation.community_overviews);
    }
  }, [recommendation, filters]);

  const handleChangeFilters = (newFilters: Record<string, any>) => {
    updateFilters({ ...filters, ...newFilters });
  };

  const categories = [
    ...recommendation.config.data_points.reduce((acc, dp) => {
      if (dp.category) {
        acc.add(dp.category);
      }
      return acc;
    }, new Set<string>()),
  ];

  return (
    <Container>
      <Header
        title={
          <TitleContainer>
            {recommendation.title}
            <CategoryTagContainer>
              {categories.map((c, index) => (
                <RecommendationCategoryTag key={index} category={c} />
              ))}
            </CategoryTagContainer>
          </TitleContainer>
        }
        breadcrumbs={<RecommendationBreadcrumb company={company.name} />}
        iconSrc="/icons/ai-blob.svg"
      />
      <>
        {!selectedCommunity && (
          <>
            <ReasoningContainer>
              <RecommendationReasoning variant="problem" description={recommendation.problem} />
              <RecommendationReasoning variant="recommendation" description={recommendation.solution} />
            </ReasoningContainer>
            <Divider />
          </>
        )}
        <Main>
          {!selectedCommunity && (
            <>
              <RecommendationCommunityListFilters
                filters={filters}
                communityOptions={communityOptions}
                onFiltersChange={handleChangeFilters}
              />
              <Divider />
            </>
          )}
          <RecommendationCommunityList data={communityOverviews} />
        </Main>
      </>
    </Container>
  );
};

export default RecommendationDetails;
