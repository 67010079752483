import React from 'react';

import DynamicColumnTable from 'components/DynamicColumnsTable/DynamicColumnsTable';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import { AMENITY_ROW_TYPES } from './constants';
import { useGetAmenities } from './customHooks';
import { getAmenitiesTableColumns } from './utils';
import { useWidgetContext } from '../Widget/WidgetContext';

const AmenitiesWidgetContent = () => {
  const { community, communityId, competitors, competitorId } = useCommunityViewContext();
  const { filters, name, note } = useWidgetContext();

  const competitorsList = competitorId
    ? [competitors.find((competitor) => competitor.id === competitorId)] // if on Competitor screen, we show only that competitors in table
    : [
        ...competitors.filter(
          (competitor: any) => !filters.competitors.length || filters.competitors.includes(competitor.id)
        ),
      ];

  const amenities = useGetAmenities([communityId, ...competitorsList.map((competitor) => competitor.id)]);
  return (
    <DynamicColumnTable
      title={name}
      subtitle={note}
      className="amenities"
      dataSource={amenities}
      community={community}
      competitors={competitorsList}
      getRowClassname={(record: any, index: number) =>
        record?.type === AMENITY_ROW_TYPES.AMENITY_CATEGORY ? 'amenity-category-row' : 'amenity-name-row'
      }
      generateTableColumns={getAmenitiesTableColumns}
    />
  );
};

export default AmenitiesWidgetContent;
