import React, { useState } from 'react';

import styled from 'styled-components';

import DynamicColumnTable from 'components/DynamicColumnsTable/DynamicColumnsTable';
import { useCommunityViewContext } from 'components/View/CommunityViewContext';

import { generateCMASummaryTableColumns, getAntdRowClassName } from './CMASummaryTableHelper';
import { prepareCMASummaryDataRowsForAntdTable } from './utils';

const Container = styled.div`
  margin: 15px 20px;
`;

const CMASummaryTable = ({ competitors }: { competitors: any[] }) => {
  const { community, CMASummaryData } = useCommunityViewContext();
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const dataSource = prepareCMASummaryDataRowsForAntdTable(expandedRowKeys, CMASummaryData?.slice(1));

  return (
    <Container>
      <DynamicColumnTable
        title="CMA Summary"
        className="cma-summary"
        community={community}
        competitors={competitors}
        dataSource={dataSource}
        enableCustomStickyHeader
        expandedRowKeys={expandedRowKeys}
        setExpandedRowKeys={setExpandedRowKeys}
        getRowClassname={getAntdRowClassName}
        generateTableColumns={generateCMASummaryTableColumns}
      />
    </Container>
  );
};

export default CMASummaryTable;
