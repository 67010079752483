import React from 'react';

import styled from 'styled-components';

import { AMENITY_ROW_TYPES } from './constants';

const Container = styled.div`
  font-family: var(--font-regular);
  font-size: 14px;
`;

const Green = styled.div`
  color: var(--positive-green);
`;

interface Props {
  record: any;
  communityIndex: number;
}

const AmenitiesTableCommunityColumn = ({ record, communityIndex }: Props) => {
  if (record.type === AMENITY_ROW_TYPES.AMENITY_CATEGORY) {
    return null;
  }

  const amenityValue =
    record.amenities[communityIndex] === true ? (
      <Green>Yes</Green>
    ) : record.amenities[communityIndex] === false ? (
      'No'
    ) : (
      '-'
    );

  return <Container>{amenityValue}</Container>;
};

export default AmenitiesTableCommunityColumn;
