import React from 'react';

import styled from 'styled-components';

import { GRAPH_WIDGET_TYPES, WIDGETS_WITH_NO_CONTROLS } from '../constants';
import { DATA_DISPLAY_TYPE, DATA_RANGE_TYPE } from '../Widget/constants';
import WidgetActions from '../Widget/WidgetActions';
import { useWidgetContext } from '../Widget/WidgetContext';

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-left: auto;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DisplayItem = styled.div`
  cursor: pointer;
  padding: 6px;
  display: flex;
  box-sizing: border-box;
  border: solid 1px var(--line-gray);

  img {
    cursor: pointer;
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-of-type {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: none;
  }
`;

const GraphWidgetDisplayControls = () => {
  const { type, rangeType, displayType, setRangeType, setDisplayType } = useWidgetContext();
  const showGraphWidgetControls = GRAPH_WIDGET_TYPES.includes(type) && !WIDGETS_WITH_NO_CONTROLS.includes(type);

  return (
    <Container>
      {showGraphWidgetControls && (
        <>
          <ItemsContainer>
            <DisplayItem title={'Current'} onClick={() => setRangeType(DATA_RANGE_TYPE.NOW)}>
              <img
                alt={DATA_RANGE_TYPE.NOW}
                src={
                  rangeType === DATA_RANGE_TYPE.NOW ? '/icons/signal-cellular-blue.svg' : '/icons/signal-cellular.svg'
                }
                style={{ width: '14px', height: '14px' }}
              />
            </DisplayItem>
            <DisplayItem title={'Over Time'} onClick={() => setRangeType(DATA_RANGE_TYPE.OVERTIME)}>
              <img
                alt={DATA_RANGE_TYPE.OVERTIME}
                src={rangeType === DATA_RANGE_TYPE.OVERTIME ? '/icons/show-chart-blue.svg' : '/icons/show-chart.svg'}
                style={{ width: '14px', height: '14px' }}
              />
            </DisplayItem>
          </ItemsContainer>
          <ItemsContainer>
            <DisplayItem title={'Graph View'} onClick={() => setDisplayType(DATA_DISPLAY_TYPE.GRAPH)}>
              <img
                src={displayType === DATA_DISPLAY_TYPE.GRAPH ? '/icons/graph-data-blue.svg' : '/icons/graph-data.svg'}
                alt={DATA_DISPLAY_TYPE.GRAPH}
                style={{
                  width: 14,
                  height: 14,
                }}
              />
            </DisplayItem>
            <DisplayItem title={'Table View'} onClick={() => setDisplayType(DATA_DISPLAY_TYPE.TABLE)}>
              <img
                src={displayType === DATA_DISPLAY_TYPE.TABLE ? '/icons/table-data-blue.svg' : '/icons/table-data.svg'}
                alt={DATA_DISPLAY_TYPE.TABLE}
                style={{
                  width: 14,
                  height: 14,
                  color: '#007aff',
                }}
              />
            </DisplayItem>
          </ItemsContainer>
        </>
      )}
      <DisplayItem style={{ border: 'none' }}>
        <WidgetActions />
      </DisplayItem>
    </Container>
  );
};

export default GraphWidgetDisplayControls;
