import React, { useContext } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';

import Button from '../../../lib/Button';
import { SELECTOR_ITEM_FILTERS_LABEL, SELECTOR_ITEM_TYPES } from '../constants';
import { SidebarContext } from '../SidebarContext';

const StyledButton = styled(Button)`
  font-size: 12px;
  line-height: 12px;
  height: 26px;
  border-radius: 13px;
  box-shadow: 0 2px 4px 0 var(--box-shadow-gray-dark);
  padding: 0px 10px;
`;

const Count = styled.span`
  color: var(--text-secondary);
  font-family: var(--font-regular);
  font-weight: 500;
  margin-left: 5px;
  &.active {
    color: var(--white);
  }
`;

interface Props {
  type: string;
  count?: number;
}

const SidebarSelectorDropdownFilterButton = ({ type, count }: Props) => {
  const { filterType, setFilterType } = useContext(SidebarContext);

  return (
    <StyledButton
      type={filterType === type ? 'primary' : 'default'}
      className={classNames({ active: false })}
      onClick={() => (type === filterType ? setFilterType(SELECTOR_ITEM_TYPES.ALL) : setFilterType(type))}
    >
      <span>{SELECTOR_ITEM_FILTERS_LABEL[type]}</span>
      <span>{Boolean(count) && <Count className={classNames({ active: filterType === type })}>{count}</Count>}</span>
    </StyledButton>
  );
};

export default SidebarSelectorDropdownFilterButton;
