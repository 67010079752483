import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

const Container = styled.div`
  padding: 16px 30px;
`;

const Title = styled.div`
  font-size: 20px;
  font-family: var(--font-bold);
`;

const SubTitle = styled.div`
  font-size: 14px;
  font-family: var(--font-regular);
  color: var(--text-secondary);
`;

const CommunityName = styled.span`
  color: var(--link-blue);
`;

const ShopDataModalHeader = () => {
  const { selectedCommunity } = useAppSelector((state) => state.global);
  return (
    <Container>
      <Title>Enter Shop Data</Title>
      <SubTitle>
        for <CommunityName>{selectedCommunity?.name}</CommunityName>
      </SubTitle>
    </Container>
  );
};

export default ShopDataModalHeader;
