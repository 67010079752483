import React from 'react';

import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 10px;
  margin-top: 5px;
  background-color: #f1f1f1;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DefaultItemRender = (originNode: React.ReactNode, file: any, fileList: any, actions: any) => (
  <Container>
    <FlexContainer>
      <CheckCircleFilled style={{ color: 'var(--positive-green)', fontSize: 15, marginRight: '5px' }} />
      <FlexContainer>
        <img alt="attachment" src="/icons/attachment.svg" style={{ marginRight: '5px' }} />
        <span>{file.name}</span>
      </FlexContainer>
    </FlexContainer>
    <CloseOutlined
      style={{ color: 'var(--gray-dark)', fontSize: 10, cursor: 'pointer' }}
      onClick={() => actions.remove()}
    />
  </Container>
);

export default DefaultItemRender;
