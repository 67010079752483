import React, { useRef, useState } from 'react';

import styled from 'styled-components';

import useArrayBoundary from 'utils/hooks/useArrayBoundary';

import { INITIAL_NUMBER_OF_COLUMNS } from './constants';
import { useCMASummaryTableStickyHeader, useSetNumberOfDisplayedColumns } from './customHooks';
import StyledDynamicColumnsTable from './StyledDynamicColumnsTable';

const Container = styled.div`
  display: flex;
  flex-flow: column;
  padding-top: 20px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .cma-summary {
    tr {
      td {
        &:first-child {
          background-color: var(--background-gray-light) !important;
        }
        &::before {
          background-color: var(--background-gray-light);
        }
        &:nth-child(2) {
          background-color: var(--background-gray-light) !important;
        }
      }
    }
  }
  .amenities {
    th {
      border-bottom: solid 1px var(--line-gray) !important;
    }
    .amenity-category-row {
      td {
        font-family: var(--font-bold);
        font-size: 16px;
        padding-top: 30px !important;
        padding-bottom: 10px !important;
        &::before {
          background-color: white;
        }
      }
    }
    .amenity-name-row {
      td {
        font-family: var(--font-medium);
        font-size: 14px;
        border-bottom: solid 1px var(--line-gray);
        &::before {
          background-color: white;
        }
      }
    }
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-family: var(--font-bold);
  padding-left: 20px;
`;

const Note = styled.div`
  font-size: 12px;
  color: var(--gray-dark);
  word-break: break-word;
  padding-left: 20px;
`;

type Props = {
  title?: string;
  subtitle?: string;
  dataSource: any;
  className?: string;
  community: any;
  competitors: any[];
  expandedRowKeys?: string[];
  enableCustomStickyHeader?: boolean;
  setExpandedRowKeys?: (payload: string[]) => void;
  getRowClassname?: (record: any, index: number) => string;
  generateTableColumns: (payload: any) => any;
};

const DynamicColumnTable = ({
  title,
  subtitle,
  dataSource,
  className,
  community,
  competitors,
  expandedRowKeys,
  enableCustomStickyHeader = false,
  setExpandedRowKeys,
  getRowClassname,
  generateTableColumns,
}: Props) => {
  const tableRef = useRef<HTMLInputElement>(null);
  const [displayedNumberOfCompetitors, setDisplayedNumberOfCompetitors] = useState<number>(INITIAL_NUMBER_OF_COLUMNS);

  const {
    minimum: competitorsMinIndex,
    maximum: competitorsMaxIndex,
    moveLeft: scrollCompetitorsLeft,
    moveRight: scrollCompetitorsRight,
    resetMinimum,
  } = useArrayBoundary({ initialMinimum: 0, initialMaximum: displayedNumberOfCompetitors - 1 });
  const onResetCallback = (i: number) => {
    setDisplayedNumberOfCompetitors(i);
    resetMinimum();
  };
  useSetNumberOfDisplayedColumns(tableRef, onResetCallback);
  useCMASummaryTableStickyHeader(enableCustomStickyHeader, competitorsMaxIndex);

  const displayedCompetitors = competitors.filter(
    (competitor: any, index: number) => index >= competitorsMinIndex && index <= competitorsMaxIndex
  );

  const columns = generateTableColumns({
    community,
    competitors,
    filteredCompetitors: displayedCompetitors,
    competitorsMinIndex,
    competitorsMaxIndex,
    displayedNumberOfCompetitors,
    expandedRowKeys,
    setExpandedRowKeys,
    scrollCompetitorsLeft,
    scrollCompetitorsRight,
  });

  return (
    <Container ref={tableRef}>
      {Boolean(title?.length) && <Title>{title}</Title>}
      {Boolean(subtitle?.length) && <Note>{subtitle}</Note>}
      <StyledDynamicColumnsTable
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowClassName={getRowClassname}
        scroll={{ y: 'max-content' }}
        rowKey="key"
        className={className}
        sticky
      />
    </Container>
  );
};

export default DynamicColumnTable;
