import React from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarMenuAdmin from './SidebarMenuAdmin';
import SidebarMenuCommon from './SidebarMenuCommon';
import SidebarMenuCommunityViews from './SidebarMenuCommunityViews';
import SidebarMenuCompany from './SidebarMenuCompany';
import SidebarMenuMysteryShopping from './SidebarMenuMysteryShopping';
import SidebarMenuOther from './SidebarMenuOther';
import SidebarMenuQuickCompetitors from './SidebarMenuQuickCompetitors';
import { ACTOR_TYPE } from '../../../constants';
import {
  isIncentivesCorporate,
  isIncentivesSalesperson,
  isMysteryShopAdmin,
  isMysteryShopShopper,
} from '../../../helpers/permissions';

const StyledMenu = styled.div`
  color: white;
  background: transparent;
  font-size: 12px;
  height: 90vh;
  overflow-y: scroll;
  user-select: none;
`;

const SidebarMenu = () => {
  const { selectedCompany, selectedCommunity, selectedManagementCompany } = useAppSelector((state) => state.global);
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const showIncentives =
    currentUser && selectedCompany && (isIncentivesCorporate(currentUser) || isIncentivesSalesperson(currentUser));
  const showCommunityCompetitors = selectedCommunity?.type == ACTOR_TYPE.COMMUNITY;
  const showShopRequests = currentUser && (isMysteryShopAdmin(currentUser) || isMysteryShopShopper(currentUser));
  const showOtherMenu = currentUser?.feature_flags?.show_beta_settings || showIncentives || Boolean(selectedCommunity);

  return (
    <StyledMenu className="mini-scroll">
      {selectedManagementCompany && !selectedCommunity && <SidebarMenuCommon />}
      {selectedCommunity && <SidebarMenuCommunityViews />}
      {selectedCompany && !selectedCommunity && <SidebarMenuCompany />}
      {showOtherMenu && <SidebarMenuOther />}
      {showCommunityCompetitors && <SidebarMenuQuickCompetitors />}
      {showShopRequests && <SidebarMenuMysteryShopping />}
      {currentUser?.isSuperuser && <SidebarMenuAdmin />}
    </StyledMenu>
  );
};

export default SidebarMenu;
