import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { RecommendationCommunityFilterType, RecommendationCommunityOverviewType } from 'types/recommendations';

import { MAX_HEIGHT } from './constants';
import RecommendationCommunityFilters from './RecommendationCommunityFilters';
import RecommendationCommunityWidgets from './RecommendationCommunityWidgets';
import useRecommendationDetailsContext from './RecommendationDetailsContext';
import RecommendationMarkdown from './RecommendationMarkdown';
import useRecommendationsContext from './RecommendationsContext';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;
  align-items: stretch;
`;

const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  a {
    font-size: 20px;
    font-weight: 900;
    color: var(--text-primary);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 20px;
  height: 20px;
`;

const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ReasoningContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ReasoningBox = styled.div<{ expanded: boolean }>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ expanded }) => (expanded ? 'unset' : 14)};
`;

const ReasoningTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: var(--text-secondary);

  span:first-child {
    font-size: 14px;
    font-weight: 900;
    color: var(--text-primary);
  }

  span:not(:first-child):before {
    content: '\\00B7';
    padding-right: 5px;
    padding-left: 5px;
  }
`;

const ReasoningItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentRight = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

const NoData = styled.div`
  width: 100%;
  height: 100%;
  color: var(--text-secondary);
  font-family: var(--font-bold);
  font-size: 16px;
  text-align: center;
  align-content: center;
`;

const ElipsisSymbol = styled.span`
  color: var(--link-blue);
  cursor: pointer;
`;

type ReasoningVariantType = 'summary' | 'details';

type ReasoningType = {
  tags: string[];
  description: string;
};

interface RecommendationCommunityProps {
  data: RecommendationCommunityOverviewType;
}

const RecommendationCommunity = ({ data }: RecommendationCommunityProps) => {
  const { careTypeOptions: allCareTypeOptions, unitTypeOptions: allUnitTypeOptions } = useRecommendationsContext();
  const { widgets, filters: globalFilters } = useRecommendationDetailsContext();
  const [widget, setWidget] = useState(widgets.length > 0 ? widgets[0] : 0);
  const [filters, setFilters] = useState<RecommendationCommunityFilterType>({
    careTypes: globalFilters.careTypes || [],
    unitTypes: [],
  });
  const [reasoningVariant, setReasoningVariant] = useState<ReasoningVariantType | undefined>();
  const [reasoning, setReasoning] = useState<ReasoningType[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const recommendationCareTypes = data.recommendations.map((r) => r.care_type_id);
  const careTypeOptions = allCareTypeOptions.filter((ct) => recommendationCareTypes.includes(Number(ct.value)));
  const recommendationUnitTypes = data.recommendations.map((r) => r.unit_type_id);
  const unitTypeOptions = allUnitTypeOptions.filter((ut) => recommendationUnitTypes.includes(Number(ut.value)));

  useEffect(() => {
    if (filters.careTypes.length === 0 && filters.unitTypes.length === 0) {
      setReasoningVariant('summary');
    } else {
      setReasoningVariant('details');
      setReasoning(
        data.recommendations.reduce((acc, r) => {
          const hasCareType = filters.careTypes.length > 0 ? filters.careTypes.includes(r.care_type_id) : true;
          const hasUnitType = filters.unitTypes.length > 0 ? filters.unitTypes.includes(r.unit_type_id) : true;

          if (hasCareType && hasUnitType) {
            acc.push({
              tags: [r.unit_type_name, r.care_type_name].filter(Boolean),
              description: `${r.problem} ${r.solution}`,
            });
          }

          return acc;
        }, [] as any[])
      );
    }
  }, [filters]);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        const contentHeight = textRef.current.clientHeight;
        setIsOverflowing(contentHeight >= MAX_HEIGHT);
      }
    };

    checkOverflow();
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (textRef.current) resizeObserver.observe(textRef.current);

    return () => resizeObserver.disconnect();
  }, [textRef.current, data]);

  const handleFiltersChange = (newFilters: Record<string, any>) => {
    setFilters((draft) => ({ ...draft, ...newFilters }));
  };

  const handleWidgetChange = (widget: number) => {
    setWidget(widget);
  };

  if (!reasoningVariant) {
    return null;
  }

  const hideFilters = widgets.length === 1;

  return (
    <Container>
      <ContentLeft>
        <Header>
          <Logo src="/icons/ai-blob.svg" />{' '}
          <Link to={`/community/${data.community_id}`} target="_blank" rel="noopener noreferrer">
            {data.community_name}
          </Link>
        </Header>
        {!hideFilters && (
          <RecommendationCommunityFilters
            filters={filters}
            careTypeOptions={careTypeOptions}
            unitTypeOptions={unitTypeOptions}
            onFiltersChange={handleFiltersChange}
          />
        )}
        {reasoningVariant === 'summary' && (
          <ReasoningContainer>
            <ReasoningItems>
              <ReasoningBox ref={textRef} expanded={expanded}>
                <RecommendationMarkdown>{data.problem}</RecommendationMarkdown>
                <br />
                <RecommendationMarkdown>{data.solution}</RecommendationMarkdown>
              </ReasoningBox>
              {isOverflowing && (
                <ElipsisSymbol onClick={() => setExpanded(!expanded)}>
                  {expanded ? 'Show Less' : 'Show More'}
                </ElipsisSymbol>
              )}
            </ReasoningItems>
          </ReasoningContainer>
        )}
        {reasoningVariant === 'details' &&
          reasoning.map((r, i) => (
            <ReasoningContainer key={i}>
              <ReasoningItems>
                <ReasoningBox ref={textRef} expanded={expanded}>
                  <ReasoningTitle>
                    {r.tags.map((tag, i) => (
                      <span key={i}>{tag}</span>
                    ))}
                  </ReasoningTitle>
                  <RecommendationMarkdown>{r.description}</RecommendationMarkdown>
                </ReasoningBox>
                {isOverflowing && (
                  <ElipsisSymbol onClick={() => setExpanded(!expanded)}>
                    {expanded ? 'Show Less' : 'Show More'}
                  </ElipsisSymbol>
                )}
              </ReasoningItems>
            </ReasoningContainer>
          ))}
        {reasoningVariant === 'details' && reasoning.length === 0 && (
          <NoData>No recommendations for selected filters</NoData>
        )}
      </ContentLeft>
      <ContentRight>
        <RecommendationCommunityWidgets
          communityId={data.community_id}
          widget={widget}
          availableWidgets={widgets}
          filters={filters}
          onWidgetChange={handleWidgetChange}
        />
      </ContentRight>
    </Container>
  );
};

export default RecommendationCommunity;
