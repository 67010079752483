import React, { useEffect, useState } from 'react';

import { Image } from 'antd';
import styled from 'styled-components';

import PdfThumbnail from './PDFThumbnail';
import { getFileExtension, isImageFile, isPDFFile } from './utils';
import PreviewUnavailable from '../../common/PreviewUnavailable';

const Container = styled.div`
  width: 134px;
  height: 134px;
`;

type FileItem = {
  file_name: string;
  file_url: string;
  file_extension: string;
};

type Props = {
  file: FileItem;
};

const BrochureAndDocsThumbnails = ({ file }: Props) => {
  const [isPDF, setIsPDF] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const fileExtension = getFileExtension(file.file_url);
    const isFilePDF = isPDFFile(fileExtension);
    const isFileImage = isImageFile(fileExtension);

    setIsPDF(isFilePDF);
    setIsImage(isFileImage);
    setShowError(!isFilePDF && !isFileImage);
  }, [file]);
  return (
    <Container>
      {isPDF && !showError && <PdfThumbnail fileUrl={file.file_url} onError={setShowError} />}
      {isImage && !showError && (
        <Image
          src={file.file_url}
          style={{ objectFit: 'cover' }}
          alt="img"
          height="134px"
          width="134px"
          preview={false}
          onError={() => {
            setShowError(true);
          }}
        />
      )}
      {showError && <PreviewUnavailable />}
    </Container>
  );
};

export default BrochureAndDocsThumbnails;
