import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import MobileTopBar from 'components/layout/Sidebar/MobileTopBar';

import { NavigationContext } from './NavigationContext';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  width: 100%;
  min-height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  background-color: white;

  @media (max-width: 768px) {
    padding-top: 50px;
    box-sizing: border-box;
  }
`;

const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  height: 100%;
`;

const BlurredOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 300px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  z-index: 6; 
`;

interface Props {
  children: React.ReactNode;
}

const BetaContent = ({ children }: Props) => {
  const location = useLocation();
  const [showMobileSidebar, setMobileSidebar] = useState(false);
  const closeMobileSidebar = () => setMobileSidebar(false);

  useEffect(() => {
    // close mobile sidebar on each location change
    closeMobileSidebar();
  }, [location]);

  return (
    <Container>
      <NavigationContext.Provider value={{
        closeMobileSidebar,
      }}>
        <MobileTopBar setMobileSidebar={setMobileSidebar} />
        {showMobileSidebar && <BlurredOverlay onClick={closeMobileSidebar} />}
        <Sidebar className={showMobileSidebar ? 'mobile' : undefined} />
        <ContentContainer>
          <Header />
          <Content>{children}</Content>
        </ContentContainer>
      </NavigationContext.Provider>
    </Container>
  );
};

export default BetaContent;
