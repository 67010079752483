import React from 'react';

import { Table } from 'antd';
import styled from 'styled-components';

const StyledDynamicColumnsTable = styled(Table)`
  &&&&&& {
    width: 100%;

    .ant-table-body {
      overflow: auto !important;
    }

    .ant-table-row {
      position: relative;

      .ant-table-row-expand-icon-cell {
        padding: 0;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
        border-bottom: 0;
      }

      img {
        // set black color to expand&collapse icons
        filter: brightness(0) invert(0);
      }
    }

    .ant-table-expanded-row-level-1 {
      .ant-table-cell {
        padding: 0;
      }
    }

    tr {
      font-family: var(--font-regular);
      font-weight: 200;
      color: var(--text-primary);
      background-color: #fff;

      &:last-child {
        td {
          padding-bottom: 20px;
        }
      }

      td {
        padding: 10px 10px 10px 0px;
        vertical-align: center;
        border-bottom: solid 1px var(--line-gray);

        &.ant-table-cell {
          background: white;
        }

        &.ant-table-cell-row-hover {
          .hover-visible {
            visibility: visible;
          }
        }

        &:first-child {
          padding-left: 20px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            height: 100%;
            width: 20px;
          }
        }
        &:last-child {
          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            right: 0;
            height: 100%;
            width: 20px;
            background-color: var(--white);
          }
        }

        &:nth-child(3) {
          padding-left: 40px;
        }
      }

      th {
        font-family: var(--font-bold);
        font-size: 12px;
        text-transform: uppercase;
        padding-left: 0;
        background-color: #fff;

        &.ant-table-cell::before {
          background-color: transparent;
        }

        &:nth-child(3) {
          padding-left: 40px;
          position: relative;
        }

        &:nth-last-of-type(2) {
          .column-header-label {
            max-width: 140px;
          }
        }
      }
    }

    .cma-summary-apartment-type-data-row {
      td {
        background-color: var(--background-gray-light) !important;
      }
    }

    .room-comparison-row {
      td {
        padding-top: 0px;

        :nth-child(2) {
          .cma-summary-border {
            background-color: var(--background-gray-light);
          }
        }
      }
    }
  }
`;

export default StyledDynamicColumnsTable;
