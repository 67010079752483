import React from 'react';
import DatePickerCalendar from 'react-datepicker';

import { Typography } from 'antd';
import styled from 'styled-components';

import { getFormattedDate } from 'helpers/dates';

const StyledSelect = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-size: 12px;
  height: 28px;
  border-radius: 4px;
  padding: 0 11px;
  background-color: var(--lighter-gray);
  border-color: var(--lighter-gray);
  color: var(--deep-blue);
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-right: 5px;
  :focus {
    color: var(--dark-blue);
    background: var(--faded-blue);
    border: solid 1px var(--button-blue);
    height: 28px;
  }
`;

const BoldText = styled(Typography.Text)`
  font-family: var(--font-bold);
  font-size: 12px;
  color: inherit;
`;

type Props = {
  selected: Date;
  minDate?: Date;
  maxDate?: Date;
  dueDate?: Date;
  label: string;
  onChange: (date: any) => void;
};

const SelectAdvancedFilterDate = ({ selected, minDate, maxDate, dueDate, label, onChange }: Props) => {
  return (
    <DatePickerCalendar
      selected={selected}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      showPopperArrow={false}
      customInput={
        <StyledSelect tabIndex={0} onClick={onChange}>
          {dueDate ? (
            <span>
              <BoldText>{label}:</BoldText>
              {` ${getFormattedDate(dueDate, 'MMMM D YYYY')}`}
            </span>
          ) : (
            label
          )}
        </StyledSelect>
      }
    />
  );
};

export default SelectAdvancedFilterDate;
