import React, { useEffect, useRef, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import classNames from 'classnames';
import styled from 'styled-components';

import {
  generateLayout,
  getUpdatedLayout,
  RESPONSIVE_GRID_LAYOUT_BREAKPOINTS,
  RESPONSIVE_GRID_LAYOUT_COLS,
  RESPONSIVE_GRID_LAYOUT_ROW_HEIGHT,
} from 'utils/helpers/layout';

import { useCommunityViewContext } from './CommunityViewContext';
import CommunityViewLayoutEmpty from './CommunityViewLayoutEmpty';
import { getWidgetFilter } from './utils';
import { updatePartialView } from '../../apis/ViewAPI';
import { WIDGET_TYPES } from '../../common/Widgets/constants';
import Widget from '../../common/Widgets/Widget/Widget';

const ResponsiveWidgetContainer = styled.div`
  & {
    .react-resizable-handle.react-resizable-handle-se {
      bottom: 5px;
      right: 5px;
      // we handle resize handle with opacity 0, and opacity 1 on resize action,
      // because if we do it with display: none, it becomes laggy
      opacity: 0;
     
    }

    :hover {
      .react-resizable-handle.react-resizable-handle-se {
        opacity: 1;
      }
    }
    .widget-container,
    .graph,
    .swot-score,
    .swot-performance,
    .reviews,
    .incentives,
    .photos { {
      width: inherit;
      height: inherit;
    }
  }
  &.map {
    .react-resizable-handle.react-resizable-handle-se {
      transform: translate(2px,2px);
    }
    :hover {
      .react-resizable-handle.react-resizable-handle-se {
        padding: 2.9px 3.1px 3.1px 2.9px;
        width: 25px;
        height: 25px;
        opacity: 0.62;
        border-radius: 8px;
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
        background-image: linear-gradient(144deg, rgba(255, 255, 255, 0.4) 21%, rgba(255, 255, 255, 0.24) 78%);
      }
    }
    .react-resizable-handle.react-resizable-handle-se::after {
      right: 9px;
      bottom: 9px;
    }
  }
  &.amenities {
    .react-resizable-handle.react-resizable-handle-se {
      right: 20px;
    }
  }
`;

const ResponsiveGridLayout = WidthProvider(Responsive);
const StyledResponsiveGridLayout = styled(ResponsiveGridLayout)`
  &.resizing {
    .react-resizable-handle-se {
      // This is to counter the react-grid-layout bug which occurs while resizing,
      // the placeholder div in some situations is placed over the div that user is resizing, and thus that div
      // loses the hover status. Consequently, its opacity becomes 0 and we need it to be 1 during the resize process.
      opacity: 1;
    }
  }
`;

type Props = {
  singleMetricItems: any[];
  graphMetricItems: any[];
  globalFilters: any;
};

const CommunityViewLayoutResponsive = ({ singleMetricItems, graphMetricItems, globalFilters }: Props) => {
  const { view, viewId, preview } = useCommunityViewContext();
  const [previousViewId, setPreviousViewId] = useState<number | null>(null);

  const [isResizing, setIsResizing] = useState(false);
  const [layout, setLayout] = useState<any>([]);
  const graphWidgetRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());
  const singleMetricWidgetRefs = useRef<Map<string, HTMLDivElement | null>>(new Map());

  useEffect(() => {
    if (view?.layout?.length > 0) {
      setLayout(view.layout);
    } else {
      const generatedLayout = generateLayout(singleMetricItems, graphMetricItems);
      setLayout(generatedLayout);
    }
  }, [singleMetricItems, graphMetricItems, view]);

  if (layout.length == 0) return <CommunityViewLayoutEmpty />;

  const handleItemChange = async (updatedLayout: any) => {
    if (isResizing) {
      setIsResizing(false);
    }
    await updatePartialView(viewId, { layout: updatedLayout });
  };

  const onLayoutChange = (newLayout: any) => {
    const moreThanOneItemChanged = Math.abs(newLayout.length - layout.length) !== 1;
    const initialLoad = newLayout.length === layout.length || moreThanOneItemChanged || newLayout.length === 0;
    const viewChanged = !previousViewId || previousViewId !== viewId;
    if (viewChanged || (initialLoad && newLayout.length !== 0)) {
      setPreviousViewId(viewId);
      return;
    }

    // widget added or removed procedure:
    const updatedLayout = getUpdatedLayout(layout, newLayout, singleMetricItems, graphMetricItems);
    setLayout(updatedLayout);
    handleItemChange(updatedLayout);
  };

  const onResizeStart = () => {
    setIsResizing(true);
  };

  return (
    <>
      <StyledResponsiveGridLayout
        className={classNames('layout', { resizing: isResizing })}
        layouts={{ xxl: layout, xl: layout, lg: layout, md: layout, sm: layout, xs: layout }}
        breakpoints={RESPONSIVE_GRID_LAYOUT_BREAKPOINTS}
        cols={RESPONSIVE_GRID_LAYOUT_COLS}
        compactType="vertical"
        rowHeight={RESPONSIVE_GRID_LAYOUT_ROW_HEIGHT}
        margin={[20, 20]}
        isResizable={!preview}
        isDraggable={!preview}
        isDroppable={false}
        style={{ width: '100%' }}
        draggableHandle={'.widget-drag-handle'}
        onDragStop={handleItemChange}
        onResizeStart={onResizeStart}
        onResizeStop={handleItemChange}
        onLayoutChange={onLayoutChange}
      >
        {singleMetricItems.map((item: any) => (
          <ResponsiveWidgetContainer key={`${item.id}`}>
            <Widget
              id={item.id}
              type={item.type}
              name={item.name}
              note={item.note}
              filters={getWidgetFilter(view?.default, globalFilters, item.filter)}
              ref={(el: HTMLDivElement | null) => singleMetricWidgetRefs.current.set(item.id, el)}
            />
          </ResponsiveWidgetContainer>
        ))}
        {graphMetricItems.map((item: any) => (
          <ResponsiveWidgetContainer
            key={`${item.id}`}
            className={classNames({
              map: item.type === WIDGET_TYPES.MAP,
              amenities: item.type === WIDGET_TYPES.AMENITIES,
            })}
          >
            <Widget
              id={item.id}
              type={item.type}
              name={item.name}
              note={item.note}
              initial_settings={item.initial_settings}
              filters={getWidgetFilter(view?.default, globalFilters, item.filter)}
              ref={(el: HTMLDivElement | null) => graphWidgetRefs.current.set(item.id, el)}
            />
          </ResponsiveWidgetContainer>
        ))}
      </StyledResponsiveGridLayout>
    </>
  );
};

export default CommunityViewLayoutResponsive;
