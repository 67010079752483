import React from 'react';

import { Space } from 'antd';
import styled from 'styled-components';

import Checkbox from 'components/lib/Checkbox';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 185px;
`;

const Title = styled.div`
  color: var(--text-primary);
  font-size: 12px;
  margin-bottom: 5px;

  &.heading {
    font-family: var(--font-bold);
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  & .ant-checkbox + span {
    font-family: var(--font-bold);
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    max-width: 136px;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
`;

interface Props {
  title: string;
  disabled?: boolean;
  options: { label: string; value: number | string }[];
  onSelect?: any;
  value: (string | number)[];
}

const CommunityViewWidgetModalSelector = ({ title, disabled = false, options, onSelect, value }: Props) => (
  <Container>
    <Title>{title}</Title>
    <Checkbox.Group style={{ width: '100%' }} onChange={onSelect} disabled={disabled} value={value}>
      <Space direction="vertical">
        {options.map((option) => (
          <StyledCheckbox title={option.label} key={option.value} className="green" value={option.value}>
            {option.label}
          </StyledCheckbox>
        ))}
      </Space>
    </Checkbox.Group>
  </Container>
);

export default CommunityViewWidgetModalSelector;
