import axios from '../config/axiosPrivate';

export const getAmenities = async (params: any) => {
  const url = '/amenities';
  const response = await axios.get(url, { params });
  return response.data;
};

export const initiateAmenitiesRequest = async (communities: number[]) => {
  const url = `/amenities/request`;

  const response = await axios.post(url, { communities });
  return response.data;
};

export const getAmenityCategories = async () => {
  const url = '/amenities/categories';
  const response = await axios.get(url);
  return response.data;
};
