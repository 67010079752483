import React, { useState } from 'react';

import styled from 'styled-components';

import { useAppSelector } from 'store/hook';

import SidebarMenuAddView from './SidebarMenuAddView';
import SidebarMenuCategoryHeader from './SidebarMenuCategoryHeader';
import SidebarMenuCompany from './SidebarMenuCompany';
import SidebarMenuItem from './SidebarMenuItem';
import { ACTOR_TYPE } from '../../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectorIcon = styled.span`
  width: 18px;
  height: 18px;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    width: 9px;
    height: 6px;
    margin: 6px 4.5px;
    background-image: url('/icons/dropdown-caret-down.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

const SidebarMenuCommunityViews = () => {
  const { views, selectedCommunity, selectedCompany } = useAppSelector((state) => state.global);
  const [showCustomViews, setShowCustomViews] = useState<boolean>(true);

  const communityType =
    selectedCommunity?.type === ACTOR_TYPE.COMPETITOR ? ACTOR_TYPE.COMPETITOR : ACTOR_TYPE.COMMUNITY;

  const defaultCompanyView = views.length ? views.slice(0, 2) : [];
  const customCompanyViews = views.filter((_, index) => index > 1);
  return (
    <Container>
      <SidebarMenuCategoryHeader title={'MAIN VIEWS'} />
      {defaultCompanyView.map((item, index) => (
        <SidebarMenuItem
          key={item.id}
          to={`/${communityType}/${selectedCommunity?.id}/view/${item.id}`}
          label={item.name}
        />
      ))}
      {selectedCompany && <SidebarMenuCompany />}
      <SidebarMenuCategoryHeader title={'CUSTOM VIEWS'}>
        <SelectorIcon onClick={() => setShowCustomViews(!showCustomViews)} />
      </SidebarMenuCategoryHeader>
      {showCustomViews &&
        customCompanyViews?.map((item, index) => (
          <SidebarMenuItem
            key={item.id}
            to={`/${communityType}/${selectedCommunity?.id}/view/${item.id}`}
            label={item.name}
          />
        ))}
      <SidebarMenuCategoryHeader>
        <SidebarMenuAddView />
      </SidebarMenuCategoryHeader>
    </Container>
  );
};

export default SidebarMenuCommunityViews;
