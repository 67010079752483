export const isPDFFile = (fileExtension: string): boolean => {
  const imageExtensions = ['PDF'];
  return imageExtensions.some((ext) => fileExtension?.toUpperCase() == ext);
};

export const isImageFile = (fileExtension: string): boolean => {
  const imageExtensions = ['JPG', 'JPEG', 'PNG', 'GIF', 'BMP', 'WEBP', 'SVG'];
  return imageExtensions.some((ext) => fileExtension?.toUpperCase() == ext);
};

export const getFileExtension = (url: string): string => {
  if (url) {
    const pathname = new URL(url).pathname;
    return pathname.substring(pathname.lastIndexOf('.') + 1);
  }
  return '';
};
