import React, { useContext } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import SidebarSelectorDropdownControlsAdmin from './SidebarSelectorDropdownControlsAdmin';
import SidebarSelectorDropdownControlsUser from './SidebarSelectorDropdownControlsUser';
import { useAppSelector } from '../../../../store/hook';
import InputDebounced from '../../../lib/InputDebounced';
import { SidebarContext } from '../SidebarContext';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px 15px 10px;
`;

const Search = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInput = styled(InputDebounced)`
  width: 100%;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
`;

const SidebarSelectorDropdownControls = () => {
  const { setFilterKeyword } = useContext(SidebarContext);
  const { currentUser } = useAppSelector((state) => state.auth);

  const handleChange = (e: any) => {
    setFilterKeyword(e?.target?.value);
  };

  return (
    <Container>
      <Search>
        <StyledInput placeholder={'Search...'} prefix={<SearchOutlined />} onChange={handleChange} />
      </Search>
      <Filters>
        {currentUser?.isSuperuser ? <SidebarSelectorDropdownControlsAdmin /> : <SidebarSelectorDropdownControlsUser />}
      </Filters>
    </Container>
  );
};
export default SidebarSelectorDropdownControls;
