import React from 'react';

import styled from 'styled-components';

import Button from 'components/lib/Button';

import { useShopDataModalContext } from './ShopDataModalContext';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 28px 20px 29.5px;
  background-color: var(--white);
  border-top: 1px solid var(--line-gray);
`;

const Text = styled.div`
  font-size: 12px;
  color: var(--text-secondary);
`;

const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledButton = styled(Button)`
  padding: 10px 43px 10px 44px;
`;

const ShopDataModalFooter = () => {
  const { handleSave, handlePrevious } = useShopDataModalContext();
  return (
    <Container>
      <Text>
        Data is saved automatically when changing steps.
        <br />
        Any old data left unchanged will be saved as current data.
      </Text>
      <Actions>
        <StyledButton onClick={handlePrevious}>Previous Step</StyledButton>
        <StyledButton type="primary" onClick={handleSave}>
          Save & Next
        </StyledButton>
      </Actions>
    </Container>
  );
};

export default ShopDataModalFooter;
