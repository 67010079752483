import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { getAmenities, getAmenityCategories } from 'apis/AmenitiesAPI';
import GradientDivider from 'components/lib/GradientDivider';
import Loader from 'components/lib/Loader';

import { AmenityCategory, Amenity as AmenityType } from './types';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: dense;
  align-items: start;
  gap: 15px;
  width: 100%;
`;

const Category = styled.div`
  border: 1px solid var(--line-gray);
  padding: 20px;
  background: white;

  &:nth-child(3n+1) {
    grid-row: span 1;
  }
  &:nth-child(3n+2) {
    grid-row: span 2;
  }
  &:nth-child(3n+3) {
    grid-row: span 1;
  }
`;

const CategoryHeader = styled.div`
  font-family: var(--font-bold);
  font-size: 20px;
`;

const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Amenity = styled.div`
  display: flex;
  align-items: center;
`;

const AmenityName = styled.div`
  padding-left: 5px;
  font-family: var(--font-bold);
  font-size: 12px;
  line-height: 12px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;

const Title = styled.div`
  color: var(--text-primary);
  font-family: var(--font-bold);
  font-size: 18px;
  margin-bottom: 6px;
  text-align: center;
`;

const Note = styled.div`
  color: var(--gray-dark);
  font-size: 14px;
  text-align: center;
`;

const Amenities = () => {
  const { competitorId } = useParams();
  const { communityId } = useParams();
  const id = Number(competitorId || communityId);

  const { data: amenities, isLoading: isLoadingAmenities } = useQuery({
    queryKey: ['amenities', id],
    queryFn: () => getAmenities({ community_id: id }),
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });
  const { data: categories, isLoading: isLoadingCategories } = useQuery({
    queryKey: ['amenities', 'categories'],
    queryFn: () => getAmenityCategories(),
    refetchOnWindowFocus: false,
  });

  const isLoading = isLoadingAmenities || isLoadingCategories;
  const showInfo = !isLoading && !amenities?.length;

  return (
    <div>
      {showInfo && (
        <Info>
          <Title>There are no amenities.</Title>
          <Note>To add amenities, request a mystery shop or use the edit button.</Note>
        </Info>
      )}
      <Container>
        {isLoading ? (
          <Loader active={isLoading} />
        ) : (
          categories
            .filter((category: AmenityCategory) =>
              amenities.find((amenity: AmenityType) => amenity.category_id === category.id)
            )
            .map((category: AmenityCategory) => (
              <Category key={category.id}>
                <CategoryHeader>{category.name}</CategoryHeader>
                <GradientDivider />
                <AmenitiesContainer>
                  {amenities
                    .filter((amenity: AmenityType) => amenity.category_id === category.id)
                    .map((amenity: AmenityType) => (
                      <Amenity key={amenity.id}>
                        <img src="/icons/check-circle.svg" height="14" width="14" />
                        <AmenityName>{amenity.name}</AmenityName>
                      </Amenity>
                    ))}
                </AmenitiesContainer>
              </Category>
            ))
        )}
      </Container>
    </div>
  );
};

export default Amenities;
