import React from 'react';
import { UseQueryResult } from 'react-query';

import { uuid } from 'short-uuid';

import { Amenity, AmenityCategory } from 'components/CommunityInfoBeta/amenities/types';
import DynamicColumnsTableColumnHeader from 'components/DynamicColumnsTable/DynamicColumnsTableColumnHeader';

import AmenitiesTableCommunityColumn from './AmenitiesTableCommunityColumn';
import { AMENITY_ROW_TYPES } from './constants';
import { WidgetFilterType } from '../types';

const COMMUNITY_COLUMN_OFFSET = 1;

export const getAmenitiesDataSource = (
  amenitiesResponse: UseQueryResult<any, unknown>[],
  categories: AmenityCategory[],
  filters: WidgetFilterType
) => {
  if (!amenitiesResponse?.length) {
    return [];
  }

  const presentCategories: AmenityCategory[] = [];

  // Fill categories with available amenities
  amenitiesResponse.forEach((result: any) => {
    if (result.isSuccess) {
      const amenities = result.data;
      amenities.forEach((amenity: Amenity) => {
        const category = categories.find((category: AmenityCategory) => category.id === amenity.category_id);
        if (!category) {
          return;
        }
        if (!category.amenities?.length) {
          category.amenities = [];
        }

        if (!category.amenities.find((a) => a.id === amenity.id)) {
          category.amenities?.push({ id: amenity.id, name: amenity.name });
        }

        if (!presentCategories.includes(category)) {
          presentCategories.push(category);
        }
      });
    }
  });

  // Prepare dataSource rows
  return presentCategories
    .sort((a: AmenityCategory, b: AmenityCategory) => a.sequence - b.sequence)
    .filter(
      (category: AmenityCategory) =>
        !filters?.amenityCategories?.length || filters?.amenityCategories?.includes(category.id)
    )
    .flatMap((category: AmenityCategory) => [
      // Amenity category header row
      {
        id: uuid(),
        type: AMENITY_ROW_TYPES.AMENITY_CATEGORY,
        name: category.name,
        amenities: [],
      },
      // The rest of category amenities
      ...(category.amenities?.map((amenity: Amenity) => ({
        id: uuid(),
        type: AMENITY_ROW_TYPES.AMENITY_NAME,
        name: amenity.name,
        amenities: amenitiesResponse?.map((communityAmenities: any) => {
          let amenityValue;
          if (!communityAmenities?.data?.length) {
            // Zero communityAmenities means that amenities are not fetched for that community (from apfm)
            amenityValue = null;
          } else {
            amenityValue = communityAmenities.data?.some((item: any) => item.id === amenity.id);
          }
          return amenityValue;
        }),
      })) || []),
    ]);
};

export const getAmenitiesTableColumns = ({
  community,
  competitors,
  filteredCompetitors,
  displayedNumberOfCompetitors,
  competitorsMinIndex,
  competitorsMaxIndex,
  scrollCompetitorsLeft,
  scrollCompetitorsRight,
}: {
  community: any;
  competitors: any[];
  filteredCompetitors: any[];
  competitorsMinIndex: number;
  competitorsMaxIndex: number;
  displayedNumberOfCompetitors: number;
  scrollCompetitorsLeft: () => void;
  scrollCompetitorsRight: () => void;
}) => {
  const communitiesList = [community, ...filteredCompetitors].filter((community) => Boolean(community));

  const disableLeftScroll = competitorsMinIndex === 0;
  const disableRightScroll =
    competitorsMaxIndex === (competitors?.length || 0) - 1 || competitors.length + 1 <= competitorsMaxIndex;

  const columns: any[] = [
    {
      title: '',
      render: (value: any, record: any) => <div>{record?.name}</div>,
    },
    {
      title: communitiesList?.length ? communitiesList[0].comunity_name : '',
      render: (value: any, record: any) => <AmenitiesTableCommunityColumn record={record} communityIndex={0} />,
    },
  ];

  if (communitiesList.length) {
    columns.push(
      ...communitiesList.slice(1).map((c, index: number) => ({
        title: () => (
          <DynamicColumnsTableColumnHeader
            index={index}
            community={c}
            filteredCompetitorsCount={filteredCompetitors.length}
            totalCompetitorsCount={competitors.length}
            scrollLeft={scrollCompetitorsLeft}
            scrollRight={scrollCompetitorsRight}
            disableLeft={disableLeftScroll}
            disableRight={disableRightScroll}
            displayedNumberOfCompetitors={displayedNumberOfCompetitors}
          />
        ),
        render: (value: any, record: any) => {
          const columnIndex = COMMUNITY_COLUMN_OFFSET + competitorsMinIndex + index;
          return <AmenitiesTableCommunityColumn record={record} communityIndex={columnIndex} />;
        },
      }))
    );
  }

  return columns;
};
