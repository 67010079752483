import React, { createContext, useContext } from 'react';

type NavigationContent = {
  closeMobileSidebar: () => void;
};

export const NavigationContext = createContext<NavigationContent>({
  closeMobileSidebar: () => {
    // Do nothing
  },
});

export const useNavigationContext = () => useContext(NavigationContext);
